<template>
  <div class="flex relative settings" :class="clss">
    <notifications width="100px" position="bottom left" />
    <div
      class="settings-body referral-body flex fixed top-0 right-0 xl:pt-20 bg-gray-600 w-full justify-around md:justify-start h-full flex-col z-50 text-sm sm:text-xl"
      v-if="showSettings"
    >
      <div
        class="login-btn-container hidden justify-between items-center ml-24 mr-16 md:flex"
      >
        <div
          class="login-btn-back text-white flex cursor-pointer justify-center items-center relative"
          @click="toggleSettings(false)"
        >
          <i class="fa-solid fa-delete-left mr-4"></i> Back to Profile
        </div>
        <a rel="nofollow"
          :href="settingsConfig.loginUrl || hubUrlWithParameters"
          target="_blank"
          class="login-btn text-enter px-10 py-4 bg-white cursor-pointer"
        >
          <div class="settings-body login-btn-text button-font-color">LOGIN</div>
        </a>
      </div>

      <div class="flex flex-col justify-around flex-1">
        <div>
          <div
            v-show="settingsConfig.addToHome"
            class="settings-row text-white cursor-pointer item-w md:ml-16 md:my-2"
            @click="addHomeScreen"
          >
            <a rel="nofollow" class="flex items-center content-center leading-none py-6">
              <div
                class="text-xl sm:text-2xl icon w-26 text-white icon-padding leading-none flex items-center"
              >
                <i class="anchor fa fa-home" color='{"hex": "#FFF"}'></i>
              </div>
              <div class="flex-1 border-l pl-8 text-size">
                Add to Home Screen
              </div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
          <hr
            v-show="settingsConfig.addToHome"
            style="height: 1px"
            class="bg-white"
          />
          <div
            v-show="settingsConfig.saveToContacts"
            class="settings-row text-white cursor-pointer item-w md:ml-16 md:my-2"
            @click="saveContact"
          >
            <a rel="nofollow" class="flex items-center content-center leading-none py-6">
              <div
                class="text-xl sm:text-2xl icon text-white icon-padding w-26 leading-none flex items-center"
              >
                <i class="anchor fa fa-user-plus" color='{"hex": "#FFF"}'></i>
              </div>
              <div class="flex-1 border-l text-size pl-8">Save to Contacts</div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
          <hr
            v-show="settingsConfig.saveToContacts"
            style="height: 1px"
            class="bg-white"
          />

          <div
            v-show="settingsConfig.copyLink"
            class="settings-row text-white cursor-pointer item-w md:ml-16 md:my-2"
            @click="copyLink"
          >
            <a rel="nofollow" class="flex items-center content-center leading-none py-6">
              <div
                class="text-xl sm:text-2xl icon w-26 text-white icon-padding leading-none flex items-center"
              >
                <i class="anchor fa fa-link" color='{"hex": "#FFF"}'></i>
              </div>
              <div class="flex-1 border-l text-size pl-8">Copy Link</div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
          <hr
            v-show="settingsConfig.copyLink"
            style="height: 1px"
            class="bg-white"
          />

          <div
            v-show="settingsConfig.contactSupport"
            class="settings-row border-white text-white cursor-pointer item-w md:ml-16 md:my-2"
          >
            <a rel="nofollow"
              :href="supportUrl"
              target="_blank"
              class="flex items-center content-center leading-none py-6"
            >
              <div
                class="text-xl sm:text-2xl w-26 icon text-white icon-padding leading-none flex items-center"
              >
                <i class="anchor fa fa-headset" color='{"hex": "#FFF"}'></i>
              </div>
              <div class="flex-1 border-l text-size pl-8">Get Support</div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
          <hr
            v-show="settingsConfig.contactSupport"
            style="height: 1px"
            class="bg-white"
          />
          <div
            v-show="settingsConfig.marketplace"
            class="settings-row border-white text-white cursor-pointer item-w md:ml-16 md:my-2"
          >
            <a rel="nofollow"
              :href="marketplaceUrl"
              target="_blank"
              class="flex items-center content-center leading-none py-6"
            >
              <div
                class="text-xl sm:text-2xl w-26 icon text-white icon-padding leading-none flex items-center"
              >
                <i
                  class="fa-sharp fa-solid fa-cart-shopping"
                  color='{"hex": "#FFF"}'
                ></i>
              </div>
              <div class="flex-1 border-l text-size pl-8">
                Go to Marketplace
              </div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
          <hr
            v-show="settingsConfig.marketplace"
            style="height: 1px"
            class="bg-white"
          />

          <div
            v-show="settingsConfig.privacyPolicy"
            class="settings-row text-white cursor-pointer item-w md:ml-16 md:my-2"
          >
            <a rel="nofollow"
              :href="privacyUrl"
              target="_blank"
              class="flex items-center content-center leading-none py-6"
            >
              <div
                class="text-xl sm:text-2xl w-26 icon text-white icon-padding border-white leading-none flex items-center"
              >
                <i class="anchor fa fa-file-pdf" color='{"hex": "#FFF"}'></i>
              </div>
              <div class="flex-1 border-l text-size pl-8">Privacy Policy</div>
              <i class="anchor fa fa-chevron-right mr-8" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="login-btn-container flex justify-center align-center items-center flex-col pb-10 md:hidden "
      >
        <a rel="nofollow"
          :href="settingsConfig.loginUrl || hubUrlWithParameters"
          target="_blank"
          class="login-btn text-enter bg-white px-4 py-3 cursor-pointer md:px-6 md:py-2 w-72 text-lg text-center"
        >
          <div class="settings-body login-btn-text button-font-color">LOGIN</div>
        </a>
        <div
          class="login-btn-back text-white flex text-lg cursor-pointer mt-6 justify-center items-center relative right-16"
          @click="toggleSettings(false)"
        >
          <i class="fa-solid fa-delete-left mr-4"></i> Back to Profile
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { profileMixin } from "../scripts/index";
import { Toast } from "toaster-js";
export default {
  mixins: [profileMixin],
  props: {
    show: {
      default: false,
      type: Boolean
    },
    settingsConfig: {
      default: () => ({
        addToHome: true,
        copyLink: true,
        saveToContacts: true,
        privacyPolicy: true,
        contactSupport: true,
        marketplace: true,
        loginUrl: ""
      }),
      type: Object
    },
    hubUrl: {
      type: String,
      default: ""
    },
    privacyUrl: {
      type: String,
      default: "https://profileme.app/privacy-policy/"
    },
    supportUrl: {
      type: String,
      default: "https://hello.profileme.app/support"
    },
    marketplaceUrl: {
      type: String,
      default: "https://hello.profileme.app/marketplace"
    },
    clss: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      showSettings: this.show,
      URI: undefined,
      hubUrlWithParameters:
        (this.hubUrl || "https://hub.profileme.app") +
        "/sign-in?_ftv=" +
        document.URL,
      isMobile: false
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.URI);
      // todo: add firebase capture event
      new Toast("Link Copied!", Toast.TYPE_DONE, 2000);
    },
    addHomeScreen() {
      this.$emit("add-to-home-screen");
    },
    saveContact() {
      this.$emit("save-as-contact");
    },
    manageCookieConsent() {
      this.$emit("manage-cookie-consent");
    },
    toggleSettings(option) {
      this.showSettings = option;
      if (!option) {
        this.$emit("on-close");
      }
    }
  },
  watch: {
    show(newVal) {
      this.toggleSettings(newVal);
    }
  },
  mounted() {
    this.URI = document.URL;
    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
  }
};
</script>
<style scoped>
.icon-padding {
  padding-left: 28px;
  padding-right: 32px;
}

.text-size {
  font-size: 1.1rem;
}
.button-font-color {
  color: #333;
}

@media screen and (min-width: 768px) {
  .item-w {
    width: 500px;
  }
}

@media screen and (max-width: 300px) {
  .icon-padding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .hide-on-300 {
    display: none;
  }
}

i.anchor {
  width: 25px;
  text-align: center;
}
</style>
