<template>
  <div class="h-full overflow-auto screen">
    <div class="h-full body">
      <div class="body min-h-full flex flex-col items-center justify-start">
        <header
          class="w-full lg:flex hidden justify-center"
          :class="getTW('header')"
          data-nosnippet="false"
        >
          <div class="w-full max-w-screen-lg flex justify-end h-16">
            <div class="flex justify-end" :class="getTW('referrals-top')">
              <button
                class="flex items-center mr-1 pr-2 flex-auto flex-grow cursor-pointer"
                :class="[
                  {
                    'add-to-contacts-btn': !pwaHandler.showSaveButton
                  },
                  {
                    'save-app-btn': pwaHandler.showSaveButton
                  },
                  getTW('referrals-top-section')
                ]"
                @click="pwaHandler.handelSaveClick"
              >
                <div
                  v-if="pwaHandler.showSaveButton"
                  class="mr-3"
                  :class="getTW('referrals-top-section-icon')"
                >
                  <i class="fad fa-download"></i>
                </div>
                <div
                  v-else
                  class="mr-3"
                  :class="getTW('referrals-top-section-icon')"
                >
                  <i class="far fa-address-book"></i>
                </div>
                <div
                  class="flex flex-col info overflow-hidden"
                  :class="getTW('referrals-top-section-body')"
                >
                  <span :class="getTW('referrals-top-section-content')">{{
                    pwaHandler.showSaveButton
                      ? 'Save Profile'
                      : 'Add to contacts'
                  }}</span>
                </div>
              </button>
              <a
                rel="nofollow"
                class="flex items-center flex-auto flex-grow cursor-pointer"
                :class="getTW('referrals-top-section')"
                @click="showReferralsEvent"
              >
                <div class="mr-3" :class="getTW('referrals-top-section-icon')">
                  <i class="custom-referral-icon"></i>
                </div>
                <div
                  class="flex flex-col info"
                  :class="getTW('referrals-top-section-body')"
                >
                  <span :class="getTW('referrals-top-section-content')"
                    >Share</span
                  >
                </div>
              </a>
            </div>
          </div>
        </header>
        <main
          class="flex flex-col lg:flex-row max-w-screen-lg w-full profile"
          :class="getTW('main')"
        >
          <div class="lg:w-2/5 w-full relative" :class="getTW('details')">
            <ratio-container
              :clss="
                'details-featured-video ' + getTW('details-featured-video')
              "
              width="100%"
              :ratio="ratio"
            >
              <dynamic
                v-if="deepIf(['banner', 'embed_video'])"
                :template="details.banner.embed_video"
                :fontColor="details.profile_styling.fontColor || undefined"
                :headerColor="details.profile_styling.headerColor || undefined"
              ></dynamic>
              <banner-carousel v-else-if="deepIf(['banner', 'slider'])">
                <swiper-slide
                  v-for="(image, index) in details.banner.slider"
                  :key="index"
                >
                  <responsive-image
                    class="w-full"
                    :prefix="imagePrefix"
                    :image="image"
                    :widthMobile="'100vw'"
                    :widthDesktop="'410px'"
                    alt="banner"
                  />
                </swiper-slide>
              </banner-carousel>
            </ratio-container>
            <head-tall
              v-if="
                deepIf(['profile_styling', 'layout']) &&
                details.profile_styling.layout == 'tall'
              "
              :details="details"
              :image-prefix="imagePrefix"
              :in-show-referrals="showReferrals"
              :globalPresets="globalPresets"
              :pwaHandler="pwaHandler"
            >
            </head-tall>
            <head-short-email
              v-else-if="details.profile_styling.layout == 'shortEmail'"
              :details="details"
              :image-prefix="imagePrefix"
              :in-show-referrals="showReferrals"
              :globalPresets="globalPresets"
              :pwaHandler="pwaHandler"
            >
            </head-short-email>
            <head-short
              v-else
              :details="details"
              :image-prefix="imagePrefix"
              :in-show-referrals="showReferrals"
              :globalPresets="globalPresets"
              :pwaHandler="pwaHandler"
            >
            </head-short>
            <div
              class="flex flex-wrap justify-around"
              :class="getTW('details-contacts-body')"
              data-nosnippet="false"
            >
              <template v-for="(contact, i) in details.contacts">
                <contact-link
                  @clicked="
                    openModal(
                      contact.contact_type,
                      contact.value,
                      contact.name,
                      details
                    )
                  "
                  v-if="contact && contact.contact_type != 'socialmedia'"
                  :clss="
                    `flex items-center w-5/12 flex-auto flex-grow pr-${getKey(
                      contact
                    )} ` + getTW('details-contacts-section')
                  "
                  as="a"
                  :link="contact.value"
                  :type="contact.contact_type"
                  :key="i"
                >
                  <div
                    class="text-xl"
                    :class="getTW('details-contacts-section-icon')"
                    v-if="contact.icon"
                  >
                    <i :class="contact.icon"></i>
                  </div>
                  <div
                    class="flex flex-col info overflow-hidden"
                    :class="getTW('details-contacts-section-body')"
                  >
                    <span
                      class="overflow-hidden overflow-ellipsis"
                      :class="[
                        {
                          'opacity-50':
                            contact.contact_type == 'phone' ||
                            contact.contact_type == 'email'
                        },
                        getTW('details-contacts-section-title')
                      ]"
                      >{{ contact.name }}</span
                    >
                    <span
                      class="overflow-hidden overflow-ellipsis"
                      :class="getTW('details-contacts-section-value')"
                      v-if="
                        contact.contact_type == 'phone' ||
                        contact.contact_type == 'email'
                      "
                      >{{ contact.value }}</span
                    >
                  </div>
                </contact-link>
              </template>
            </div>
            <a
              class="flex items-center py-3"
              @click="
                captureEvent({
                  value: details.call_to_action.link,
                  type: 'call-to-action',
                  action: 'click',
                  title: details.call_to_action.content
                })
              "
              v-if="deepIf(['call_to_action'])"
              :class="getTW('details-footer')"
              :href="details.call_to_action.link"
              target="_blank"
            >
              <div class="mr-2 text-xl" :class="getTW('details-footer-icon')">
                <i :class="details.call_to_action.icon"></i>
              </div>
              <div
                :class="getTW('details-footer-content')"
                v-if="deepIf(['call_to_action', 'content'])"
              >
                <dynamic
                  :template="details.call_to_action.content"
                  fontColor=""
                  headerColor=""
                ></dynamic>
              </div>
            </a>
          </div>
          <article
            class="lg:mt-12 lg:pl-10 lg:w-3/5 w-full"
            :class="getTW('details-right')"
          >
            <div :class="getTW('bio')">
              <template v-for="(info, i) in details.additional_information">
                <collapse
                  v-if="!isModal(getKey(info))"
                  :key="i"
                  @triggerAnalytics="
                    captureEvent({
                      value: '',
                      type: 'dropdown',
                      action: 'open',
                      title: `${info.title}`
                    })
                  "
                  :header-icon="info.icon"
                  :loadIndex="++i"
                  :details="details"
                  :image-prefix="imagePrefix"
                  :globalPresets="globalPresets"
                  :clss="getKey(info) ? `pr-${getKey(info)}` : ''"
                >
                  <template v-slot:head>
                    <div>
                      <h2 :class="getTW('bio-collapse-head-title')">
                        {{ info.title }}
                      </h2>
                    </div>
                  </template>
                  <template v-slot:body>
                    <div class="rich-text ql-editor">
                      <dynamic
                        v-if="!isLeadForm(info.description)"
                        :template="buildElement(info.description)"
                        :fontColor="
                          details.profile_styling.fontColor || undefined
                        "
                        :headerColor="
                          details.profile_styling.headerColor || undefined
                        "
                      ></dynamic>
                      <render-component-from-string
                        v-else
                        :inData="{
                          leadFormData,
                          profileEmail: getContactDetail(
                            'email',
                            'email',
                            details.contacts
                          )
                        }"
                        :title="info.title"
                        :template="getForm(info.description)"
                      />
                    </div>
                  </template>
                </collapse>
              </template>
              <collapse
                v-if="deepIf(['qrcode', 'url'])"
                header-icon="fal fa-qrcode"
                :aliveOnLoad="true"
                :details="details"
                @triggerAnalytics="
                  captureEvent({
                    value: '',
                    type: 'qr_code',
                    action: 'view-media',
                    title: ''
                  })
                "
                :globalPresets="globalPresets"
              >
                <template v-slot:head>
                  <h2 :class="getTW('bio-collapse-head-title')">
                    <span class="personal-qrcode"
                      >Scan my <span class="normal-case">QR</span> code</span
                    >
                  </h2>
                </template>
                <template v-slot:body>
                  <img
                    loading="lazy"
                    v-if="details.qrcode.url"
                    :src="details.qrcode.url"
                    alt="qrcode"
                    style="width: 100%"
                  />
                </template>
              </collapse>
            </div>
            <div
              class="flex justify-between lg:hidden add-to-contacts-btn"
              :class="getTW('referrals-bottom')"
            >
              <a
                rel="nofollow"
                class="flex items-center w-5/12 mr-1 pr-2 flex-auto flex-grow cursor-pointer save-app-btn"
                :class="[
                  {
                    'add-to-contacts-btn': !pwaHandler.showSaveButton
                  },
                  {
                    'save-app-btn': pwaHandler.showSaveButton
                  },
                  getTW('referrals-bottom-section')
                ]"
                @click="pwaHandler.handelSaveClick"
              >
                <div
                  v-if="pwaHandler.showSaveButton"
                  class="mr-3"
                  :class="getTW('referrals-bottom-section-icon')"
                >
                  <i class="fad fa-download"></i>
                </div>
                <div
                  v-else
                  class="mr-3"
                  :class="getTW('referrals-bottom-section-icon')"
                >
                  <i class="far fa-address-book"></i>
                </div>
                <div
                  class="flex flex-col info overflow-hidden"
                  :class="getTW('referrals-bottom-section-body')"
                >
                  <span :class="getTW('referrals-bottom-section-content')">{{
                    pwaHandler.showSaveButton
                      ? 'Save Profile'
                      : 'Add to contacts'
                  }}</span>
                </div>
              </a>
              <a
                rel="nofollow"
                class="flex items-center w-5/12 pr-2 flex-auto flex-grow cursor-pointer"
                :class="getTW('referrals-bottom-section')"
                @click="showReferralsEvent"
              >
                <div
                  class="mr-2"
                  :class="getTW('referrals-bottom-section-icon')"
                >
                  <i class="fad fa-share-square"></i>
                </div>
                <div
                  class="flex flex-col info"
                  :class="getTW('referrals-bottom-section-body')"
                >
                  <span :class="getTW('referrals-bottom-section-content')"
                    >Share
                  </span>
                </div>
              </a>
            </div>
            <div
              v-if="deepIf(['company', 'logo', 'url'])"
              class="flex justify-center"
              :class="getTW('logo')"
            >
              <responsive-image
                class="w-2/4"
                :class="getTW('logo-image')"
                :prefix="imagePrefix"
                :image="details.company.logo"
                :alt="details.company.name"
                :widthMobile="'50vw'"
                :widthDesktop="'307px'"
              />
            </div>
            <div
              v-if="details.contacts"
              class="flex"
              :class="getTW('social-media')"
            >
              <template v-for="(contact, i) in details.contacts">
                <a
                  v-if="contact.contact_type == 'socialmedia'"
                  class="leading-none flex items-center justify-center"
                  :title="contact.name"
                  :href="contact.value"
                  :noColor="false"
                  :class="getTW('social-media-icons')"
                  :key="i"
                  target="_blank"
                  v-on:click="
                    captureEvent({
                      value: contact.value,
                      type: contact.contact_type,
                      action: 'click',
                      title: `${contact.name}`
                    })
                  "
                >
                  <i :class="contact.icon"></i>
                </a>
              </template>
              <div
                :class="`settings-btn shadow flex justify-center items-center absolute right-0`"
              >
                <i
                  class="fas fa-cog box-content text-gray-600 text-2xl"
                  @click="showSettings = true"
                />
              </div>
            </div>
          </article>
        </main>
        <footer class="w-full" :class="getTW('footer')">
          <div :class="getTW('footer-content')" v-if="deepIf(['page_footer'])">
            <dynamic
              :template="details.page_footer"
              fontColor=""
              headerColor=""
            ></dynamic>
          </div>
          <a
            class="mt-2 profile-me-plug"
            target="_blank"
            href="https://hello.profileme.app/info"
            >Need Help or Want a Card like this? Click Here | ProfileMe©</a
          >
        </footer>
      </div>
    </div>
    <popup
      v-for="(info, i) in getModals"
      :key="i"
      :name="getKey(info)"
      @close="closeModal"
    >
      <template v-slot:default>
        <div class="p-4 rich-text ql-editor">
          <dynamic
            v-if="!isLeadForm(info.description)"
            :template="buildElement(info.description)"
            :fontColor="details.profile_styling.fontColor || undefined"
            :headerColor="details.profile_styling.headerColor || undefined"
          ></dynamic>
          <render-component-from-string
            v-else
            :inData="{
              leadFormData,
              profileEmail: getContactDetail('email', 'email', details.contacts)
            }"
            :title="info.title"
            :template="getForm(info.description)"
          />
        </div>
      </template>
    </popup>
    <settings
      :show="showSettings"
      :settingsConfig="getSettingsConfig()"
      :hubUrl="details?.company?.customHubUrl"
      :privacyUrl="privacyUrl"
      :supportUrl="supportUrl"
      @on-close="showSettings = false"
      @save-as-contact="pwaHandler.vcfDownloadUrl(details)"
      @add-to-home-screen="pwaHandler.triggerInstall(details)"
      @manage-cookie-consent="manageCookieConsent"
    />
  </div>
</template>
<!-- eslint-disable vue/no-unused-components -->
<script>
import { $vfm } from 'vue-final-modal';
import headTall from '../heads/head-tall.vue';
import headShort from '../heads/head-short.vue';
import headShortEmail from '../heads/head-short-email.vue';
import collapse from '../components/classic/collapse.vue';
import dynamic from '../components/dynamic-component.vue';
import contactLink from '../components/contact-link.vue';
import bannerCarousel from '../components/banner-carousel.vue';
import ratioContainer from '../components/ratio-container.vue';
import { profileMixin } from '../scripts/index';
import 'node-self';
import { isEmpty, isNil } from 'ramda';
import ResponsiveImage from '../components/classic/responsive-image.vue';
import renderComponentFromString from '../components/render-component-from-string.vue';
import popup from '../components/popup.vue';
import { SwiperSlide } from 'swiper/vue';
import settings from '../components/settings.vue';
export default {
  mixins: [profileMixin],
  components: {
    collapse,
    dynamic,
    contactLink,
    settings,
    ratioContainer,
    headTall,
    headShort,
    headShortEmail,
    SwiperSlide,
    ResponsiveImage,
    renderComponentFromString,
    popup,
    bannerCarousel
  },
  mounted() {
    this.updateRatio();
  },
  data() {
    return {
      showReferrals: false,
      ratio: 0.55,
      showSettings: false,
      leadFormRegex: /<p>##(.*?)##<\/p>/g
    };
  },
  watch: {
    details() {
      this.updateRatio();
    }
  },
  computed: {
    getModals() {
      const infos = this.details.additional_information
        ? this.details.additional_information
        : [];

      return infos.reduce((acc, cur) => {
        if (this.isModal(this.getKey(cur))) acc.push(cur);
        return acc;
      }, []);
    }
  },
  methods: {
    openModal(type, value, name) {
      if (type == 'modal') {
        $vfm.show(`modal:${value}`);
      }
      this.captureEvent({
        action: type == 'modal' ? 'open' : 'click',
        type: type,
        value: value,
        title: name
      });
    },
    getForm(body) {
      const tags = body.match(this.leadFormRegex);
      const stripTag = (tag) => tag.replace(/(<p>)|#|(<\/p>)/g, '');

      if (!tags) return { bodyText: body, tags: [] };

      const bodyText = tags.reduce((acc, cur) => {
        return acc.replace(cur, `##${stripTag(cur)}##`);
      }, body);

      return {
        bodyText,
        tags: tags.map((tag) => stripTag(tag))
      };
    },
    buildElement(body) {
      let bodyText = body;
      const allElements = body ? body.match(/<p>@@(.*?)@@<\/p>/g) : [];
      if (allElements) {
        allElements.forEach((tag) => {
          const elementObject = JSON.parse(
            tag.replace(/(<p>)|@@|(<\/p>)/g, '')
          );

          if (elementObject.tag) {
            let newElement = `<${elementObject.tag} attr>innerHTML</${elementObject.tag}>`;

            for (const key in elementObject) {
              if (key != 'tag') {
                if (key == 'innerHTML') {
                  newElement = newElement.replace(key, elementObject[key]);
                } else {
                  newElement = newElement.replace(
                    'attr',
                    `${key}="${elementObject[key]}" attr`
                  );
                }
              }
            }

            newElement = newElement.replace(' attr', '');
            newElement = newElement.replace('innerHTML', '');
            bodyText = bodyText.replace(tag, newElement);
          }
        });
      }
      return bodyText;
    },
    updateRatio() {
      if (this.deepIf(['banner', 'slider'])) {
        this.ratio =
          this.details.banner.slider[0].height /
          this.details.banner.slider[0].width;
      }
    },
    showReferralsEvent() {
      this.showReferrals = false;
      this.$nextTick(() => {
        this.showReferrals = true;
      });
    },
    isLeadForm(info) {
      return (
        !isNil(info) &&
        !isNil(info.match(this.leadFormRegex)) &&
        !isEmpty(info.match(this.leadFormRegex))
      );
    },
    isModal(key) {
      return key.includes('modal:');
    }
  }
};
</script>
<style scoped>
.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
