<template>
  <a rel="nofollow"
    v-if="formatLink(link, type)"
    :class="clss"
    v-on:click="handleClick"
    :href="formatLink(link, type)"
    :target="type == 'url' ? '_blank' : ''"
    :type="type"
  >
    <slot></slot>
  </a>
  <button :class="clss" v-on:click="handleClick" :type="type" v-else>
    <slot></slot>
  </button>
</template>
<script>
import { formatLink } from '../scripts/index';

export default {
  props: ['as', 'clss', 'link', 'type'],
  methods: {
    formatLink,
    handleClick(e) {
      if (this.type == 'modal') {
        e.preventDefault();
      }
      this.$emit('clicked', true);
    }
  }
};
</script>
