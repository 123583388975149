<template>
  <div v-if="show" class="overlay" @click="onClose">
    <div
      @click="
        (e) => {
          e.stopPropagation();
        }
      "
      :class="
        isFirefox
          ? 'box arrow-firefox dynamic-width borders-box-radius-corner'
          : 'box arrow-safari dynamic-width borders-box-radius'
      "
    >
      <div class="flex flex-col justify-between h-full">
        <div class="relative m-8">
          <div class="profile-image" v-if="profileImage">
            <responsive-image
              class="rounded-full"
              :image="profileImage"
              :rounded="true"
              :full-name="profileName"
              :width="512"
              :widthMobile="'120px'"
              :widthDesktop="'100%'"
              :alt="profileName"
            />
          </div>
          <span
            @click="onClose"
            class="grey--text close-button text-xl cursor-pointer"
            >x</span
          >
        </div>
        <div class="flex-1">
          <div
            class="profile-title font-medium text-2xl md:text-2xl text-center pb-8 mt-8 mx-6 text-color"
          >
            Add {{ profileName || '' }} <br />
            to your Home Screen?
          </div>
          <div class="profile-message text-base text-center pb-10 text-color">
            Save my profile for quick and easy access to all my details while
            you're on the go.
          </div>
        </div>
        <div class="profile-consent pb-14 mx-8">
          <p v-if="!isFirefox" class="text-center text-sm text-color">
            Tap the
            <i
              class="fak fa-icon-share mx-1"
              style="font-size: 24px; color: rgb(0, 108, 255)"
            ></i>
            below then "Add to Home Screen"
          </p>
          <p v-else class="text-center text-sm text-color">
            Just open your browser 'Settings'
            <i class="fas fa-ellipsis-v mx-1"></i> and tap on 'Install'
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import responsiveImage from './responsive-image.vue';
import { deepValue } from '../scripts/index';
export default {
  components: { responsiveImage },
  props: {
    profileName: String,
    profileImage: String,
    show: Boolean,
    isFirefox: Boolean
  },
  data: function () {
    return {
      cookiePolicyUrl: 'https://profileme.co.za/cookies'
    };
  },
  computed: {
    getName: function () {
      return (
        this.deepValue('person', 'first_name') +
        ' ' +
        this.deepValue('person', 'last_name')
      );
    }
  },
  methods: {
    deepValue(...strArray) {
      return deepValue(this.details, strArray);
    },
    onClose: function () {
      this.$emit('onClose', false);
    }
  }
};
</script>

<style scoped>
.profile-message {
  margin: 0px 50px;
}
.close-button {
  position: absolute;
  right: -20px;
  color: rgb(201, 201, 201);
  bottom: 0px;
}
.profile-image {
  position: absolute;
  left: 50%;
  bottom: -70px;
  border-radius: 50%;
  margin-bottom: 30px;
  transform: translate(-50%, 0);
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 6px 0px 6px -6px rgb(124, 124, 124),
    -6px 0px 6px -6px rgba(124, 124, 124, 1),
    0 8px 6px -6px rgba(124, 124, 124, 1);
}
.share-icon {
  max-width: 20px;
  max-height: 20px;
}

.borders-box-radius {
  border-radius: 4px;
}

.borders-box-radius-corner {
  border-radius: 4px;
  border-bottom-right-radius: 0;
}

.overlay {
  z-index: 999;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dynamic-width {
  max-width: 350px;
}

.box.arrow-safari:after {
  content: ' ';
  position: absolute;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, 0);
  bottom: -15px;
  border-top: 15px solid white;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

.box.arrow-firefox:after {
  content: ' ';
  position: absolute;
  right: 0;
  overflow: auto;
  bottom: -15px;
  border-top: 15px solid white;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

.box {
  z-index: 1000;
  max-height: 90%;
  background-color: white;
  position: fixed;
  border: none;
  margin: auto;
  bottom: 30px;
  left: 0;
  right: 0;
}
@media screen and (min-width: 1024px) {
  .box {
    top: 70px;
    left: unset;
    bottom: unset;
    z-index: 1000;
    max-height: 90%;
    background-color: white;
    position: fixed;
    border: none;
    right: 30px;
  }
  .box.arrow-safari:after {
    left: 50%;
    transform: translate(-50%, 0);
    top: -14px;
    bottom: unset;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
  }

  .box.arrow-firefox:after {
    right: 4px;
    top: -14px;
    bottom: unset;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
  }
}

.text-color {
  color: #333;
}

@media screen and (max-width: 400px) {
  .profile-message {
    margin: 0px 40px;
  }
}

@media screen and (max-width: 600px) {
  .dynamic-width {
    max-width: 85%;
  }
}

@media screen and (min-width: 768px) {
  .box.arrow-safari::before {
    content: ' ';
    position: absolute;
    right: 0;
    overflow: auto;
    top: -15px;
    border-bottom: 15px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: none;
  }
  .box.arrow-safari::after {
    display: none;
  }
  .close-button {
    position: absolute;
    left: -20px;
    color: rgb(201, 201, 201);
    bottom: 0px;
  }
  .borders-box-radius {
    border-radius: 4px;
    border-top-right-radius: 0;
  }
}
</style>
