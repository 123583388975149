<template>
  <video
    v-if="/.(mov|mp4)$/.test(image)"
    :style="`${rounded ? 'border-radius:50%' : ''} height: auto; width: 100%`"
    muted
    loop
    autoplay
    playsinline
  >
    <source :src="image" type="video/mp4" />
  </video>
  <img
    v-else-if="image"
    loading="lazy"
    :style="rounded ? 'border-radius:50%' : ''"
    :src="sizedImage(image, maxWidth)"
    :srcset="srcset"
    :sizes="`(min-width: ${breakpoint}px) ${widthDesktop}, ${widthMobile}`"
    :alt="alt"
    decoding="async"
  />
</template>
<script>
/**
 * The jest of this component is, either we use the min between the the desktopWidth
 * or the image width. Or we use the mobile widths responsively.
 * /w_auto/f_auto
 */
export default {
  props: {
    rounded: Boolean,
    image: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 400
    },
    widthMobile: {
      type: String,
      default: ''
    },
    widthDesktop: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    breakpoint: {
      type: Number,
      default: 1024
    }
  },
  methods: {
    sizedImage(path, width) {
      if (path.includes('res.cloudinary.com')) {
        return path
          .replace('upload', 'upload/w_' + width)
          .replace('/w_auto/f_auto', '')
          .replace('/w_auto', '');
      }
      return path;
    },
    getMobileFractionWidth(width) {
      return width * (parseInt(this.widthMobile) / 100);
    },
    convertFromRemToPx(width = '') {
      if ((width || '').includes('rem')) return parseInt(width) * 16;
      return parseInt(width);
    }
  },
  computed: {
    srcset() {
      return this.widths
        .map(
          (width) =>
            `${this.sizedImage(this.image, Math.round(width))} ${Math.round(
              width
            )}w`
        )
        .join(', ');
    },
    maxWidthThatWorksButBecauseItWorksItsBroken() {
      if (
        !this.widthDesktop.endsWith('px') &&
        !this.widthDesktop.endsWith('rem')
      )
        return Math.min(this.width, 400);

      const widthDesktop = parseInt(this.widthDesktop);

      const widthMobile =
        this.widthDesktop.endsWith('px') || this.widthDesktop.endsWith('rem')
          ? this.convertFromRemToPx(this.widthMobile)
          : this.getMobileFractionWidth(this.breakpoint);
      // highest render width
      return Math.min(this.width, Math.max(widthDesktop, widthMobile));
    },
    /**
     * The max width is the min between the max between
     * the mobile and desktop width converted into
     * px and the image width. If the desktop is not a px,
     * then we just return the image width;
     */
    maxWidth() {
      if (!this.widthDesktop.endsWith('px')) {
        return Math.min(this.width || 400, 400);
      } else {
        let wD = parseInt(this.widthDesktop);
        let wM = parseInt(this.widthMobile);
        if (!this.widthMobile.endsWith('px')) {
          wM = (wM / 100) * this.breakpoint;
        }
        return Math.min(this.width || 400, Math.max(wM, wD));
      }
    },
    /**
     * Returns the mobile widths with the wax width as its max value
     */
    widths() {
      /**
       * The mobile widths are either the fractions of the vw for 4 different hardcoded
       * sizes, or they are the mobile width if it has a px value
       * note: parseInt('50px') => 50
       */
      const widths = this.widthMobile.includes('vw')
        ? [384, 640, 768, 1024].map(this.getMobileFractionWidth)
        : [parseInt(this.widthMobile)];

      const filteredWidths = widths.filter((w) => w < this.maxWidth);

      filteredWidths.push(this.maxWidth);
      return filteredWidths;
    }
  }
};
</script>
