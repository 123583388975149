import { createVCF, profileMixin } from '../scripts/index';
import { formatForSuperscript } from '../functions/wrappers/title-info-wrapper';
import DOMPurify from 'dompurify';

import ResponsiveImage from '../components/classic/responsive-image.vue';
import referrals from '../components/referrals.vue';

export default () => ({
  components: {
    referrals,
    ResponsiveImage
  },
  methods: {
    vcfDownloadUrl(data) {
      return createVCF(data);
    },
    updateWidth() {
      this.containerWidth = this.$refs.headContainer.clientWidth;
    },
    formatForSuperscript,
    sanitize: DOMPurify.sanitize
  },
  computed: {
    titleSize() {
      return this.details.title_size
        ? `font-size: ${this.details.title_size * this.fontScale}px`
        : '';
    },
    subtitleSize() {
      return this.details.subtitle_size
        ? `font-size: ${this.details.subtitle_size * this.fontScale}px`
        : '';
    },
    fontScale() {
      return this.containerWidth > 384
        ? (this.containerWidth - 384) / 1000 + 1
        : 1;
    }
  },
  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  props: ['inShowReferrals'],
  mixins: [profileMixin],
  data() {
    return {
      containerWidth: 0
    };
  }
});
