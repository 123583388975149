<script setup>
const emit = defineEmits();
const onClick = () => {
  emit('click');
};
</script>
<template>
  <div class="settings-btn flex justify-center items-center">
    <i class="fas fa-cog text-2xl box-content" @click="onClick" />
  </div>
</template>
