<script setup>
import { chain, includes } from 'lodash';

defineProps({
  bannerBackground: {
    type: String,
    default: ''
  },
  containerClass: {
    type: String,
    default: ''
  }
});

const isVideo = (url) =>
  chain(url)
    .split('.')
    .last()
    .thru((value) => includes(['webp', 'mp4', 'mov'], value))
    .value();
</script>
<template>
  <div
    :class="{
      'main-container-custom': bannerBackground,
      'main-container': !bannerBackground,
      relative: isVideo(bannerBackground)
    }"
  >
    <div
      v-if="isVideo(bannerBackground)"
      class="w-full h-full overflow-hidden absolute"
    >
      <video
        class="relative w-full -top-1/2"
        autoplay
        muted
        loop
        :src="bannerBackground"
      ></video>
    </div>
    <div
      class="w-full flex justify-center"
      :style="
        bannerBackground && !isVideo(bannerBackground)
          ? `background-image: url(${bannerBackground})`
          : ''
      "
      :class="{
        'main-container-inner-custom': bannerBackground,
        'main-container-inner': !bannerBackground
      }"
    >
      <main class="info flex" :class="containerClass">
        <slot></slot>
      </main>
    </div>
  </div>
</template>
