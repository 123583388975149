import { find, head, pathOr, pipe, propEq } from 'ramda';
export default (profile) => {
  return {
    get desktopImage() {
      return pipe(
        pathOr([], ['banners']),
        find(propEq('type', 'desktopBanner')),
        pathOr([], ['banner', 'slider']),
        head,
        pathOr([], ['url'])
      )(profile);
    },
    get desktopBanner() {
      return pipe(
        pathOr([], ['banners']),
        find(propEq('type', 'desktopBanner')),
        pathOr([], ['banner', 'slider'])
      )(profile);
    },
    get mobileBanner() {
      return pipe(
        pathOr([], ['banners']),
        find(propEq('type', 'mobileBanner')),
        pathOr([], ['banner', 'slider'])
      )(profile);
    },
    get videoEmbed() {
      return pipe(
        pathOr([], ['banners']),
        find(propEq('type', 'mobileBanner')),
        pathOr([], ['banner', 'embed_video'])
      )(profile);
    },
    get logo() {
      if (pathOr(false, ['logo', 'url'], profile))
        return pathOr(false, ['logo'], profile);

      return {
        ...pathOr('', ['company', 'logo'], profile),
        alt: pathOr('', ['company', 'name'], profile)
      };
    },
    get bannerBackground() {
      return pathOr('', ['banner', 'slider', '0', 'url'], profile);
    }
  };
};
