<template>
  <div class="cookie-popup-container" v-show="showCookieConsent">
    <div class="base base-width">
      <p class="base-header mb-2">
        This website uses cookies for a better user experience
      </p>
      <hr />
      <div class="button-container">
        <button class="cookie-button primary" v-on:click="allowAll">
          Accept
        </button>
      </div>
      <div class="options-container">
        <button class="option-text cursor-pointer" v-on:click="allowNecessary">
          Only Necessary
        </button>
        <a
          target="_blank"
          href="https://profileme.app/cookies-policy/"
          class="option-text cursor-pointer"
          >Our Cookies Policy</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cookieExists, createCookie } from '../scripts/index';

export default {
  inject: ['captureEvent'],
  props: {
    show: Boolean
  },
  data: function () {
    return {
      analyticsChecked: true,
      showCookieConsent: false
    };
  },
  mounted() {
    this.showCookieConsent = !cookieExists('_consent');
  },
  methods: {
    allowNecessary: function () {
      createCookie(
        '_consent',
        JSON.stringify({
          necessary: true,
          analytics: false
        }),
        '100y'
      );
      this.handleConcentClick(false);
    },
    // 100y is the correct configuration
    allowAll: function () {
      createCookie(
        '_consent',
        JSON.stringify({
          necessary: true,
          analytics: true
        }),
        '100y'
      );
      this.handleConcentClick(true);
    },
    handleConcentClick(allowedToIntrude) {
      this.$emit('handled', allowedToIntrude);
      this.showCookieConsent = false;
      this.captureEvent({
        value: allowedToIntrude,
        action: 'click',
        type: 'accept-cookies',
        title: ''
      });
    }
  }
};
</script>

<style>
.base {
  text-align: center;
  position: fixed;
  z-index: 60;
  bottom: 10px;
  right: 10px;
  color: #4a4a4a;
  padding: 15px 25px;
  background: rgba(255, 255, 255, 0.92);
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(89, 89, 89, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(89, 89, 89, 1);
  box-shadow: 0px 0px 5px 0px rgba(89, 89, 89, 1);
}

.base-header {
  font-size: 0.75rem;
  font-weight: 600;
}

.base-width {
  left: auto;
  margin-left: 10px;
}

.drop {
  margin-bottom: 5px;
}

.button-container {
  margin: 10px 0;
}

.cookie-container {
  display: inline-block;
  border: 1px solid black;
  padding: 5px;
}

.cookie-button {
  padding: 10px 50px;
  background: grey;
  color: white;
  margin: 5px 5px;
  border-radius: 15px;
}

.cookie-button.primary {
  background: #2389a9;
}

.input-container {
  display: inline-block;
  margin: 2px 10px;
}

.options-container {
  display: flex;
  justify-content: space-between;
}

.option-text {
  font-size: 0.75rem;
  text-decoration: underline;
}

@media only screen and (max-width: 1024px) {
  .base-width {
    left: 10px;
    margin-left: 0px;
  }
}
</style>
