<template>
  <div
    :class="[
      { 'open-collapse': open, 'closed-collapse': !open },
      clss,
      getTW('bio-collapse')
    ]"
  >
    <div
      class="flex justify-between items-center cursor-pointer h-12 lg:h-14 sm:h-16"
      :class="getTW('bio-collapse-head')"
      @click="changeOpenEvent"
    >
      <div class="flex items-center">
        <div
          v-if="!hideHeaderIcon && headerIcon"
          :class="getTW('bio-collapse-head-icon')"
        >
          <i :class="headerIcon"></i>
        </div>
        <slot name="head"></slot>
      </div>
      <div :class="getTW('bio-collapse-head-chevron')">
        <i v-if="open" class="fas fa-chevron-up"></i>
        <i v-else class="fas fa-chevron-down"></i>
      </div>
    </div>
    <div
      class="h-0"
      :class="[getTW('bio-collapse-body'), { 'overflow-hidden': !isOpen }]"
      :style="bodyHeight"
    >
      <div
        class="py-2 h-auto"
        :class="[{ open: open }, getTW('bio-collapse-body-inner')]"
        ref="innerBody"
      >
        <div v-if="open || keepAliveFlag">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
    <div
      :class="getTW('bio-collapse-footer')"
      @click="closeFromBottom"
      v-show="open"
    >
      <i
        class="fas fa-chevron-up"
        :class="getTW('bio-collapse-footer-chevron')"
      ></i>
    </div>
  </div>
</template>
<script>
import { profileMixin } from '../../scripts/index';

export default {
  name: 'colapse-comp',
  mixins: [profileMixin],
  props: {
    headerIcon: {
      default: '',
      type: String
    },
    hideHeaderIcon: {
      default: false,
      type: Boolean
    },
    keepAlive: {
      default: true,
      type: Boolean
    },
    aliveOnLoad: {
      default: false,
      type: Boolean
    },
    loadIndex: {
      defaultStatus: 0,
      type: Number
    },
    clss: {
      default: '',
      type: String
    }
  },

  data() {
    return {
      open: false,
      isOpen: false,
      keepAliveFlag: this.aliveOnLoad,
      bodyHeight: 'height: 0px'
    };
  },
  created() {
    if (!this.keepAliveFlag && this.loadIndex)
      setTimeout(() => {
        this.keepAliveFlag = true;
      }, 1000 * this.loadIndex);
  },
  methods: {
    changeOpenEvent() {
      if (this.open) {
        this.bodyHeight = `height: ${this.$refs.innerBody.clientHeight}px`;
        this.isOpen = false;
        setTimeout(() => {
          this.bodyHeight = 'height: 0px';
          setTimeout(() => {
            this.open = false;
            this.keepAliveFlag = this.keepAlive;
          }, 200);
        }, 20);
      } else {
        this.open = true;
        this.$emit('triggerAnalytics');
        this.$nextTick(() => {
          this.bodyHeight = `height: ${this.$refs.innerBody.clientHeight}px`;
          setTimeout(() => {
            this.isOpen = true;
            this.bodyHeight = 'height: auto';
          }, 200);
        });
      }
    },
    closeFromBottom() {
      const startPos = document.body.scrollTop;
      const startHeight = this.$refs.innerBody.parentElement.clientHeight;
      this.changeOpenEvent();
      const updater = setInterval(() => {
        document.body.scrollTop =
          startPos -
          startHeight +
          this.$refs.innerBody.parentElement.clientHeight;
      });
      setTimeout(() => {
        clearInterval(updater);
      }, 200);
    }
  }
};
</script>
<style scoped>
div.bio-collapse-body {
  transition: height ease-in-out 200ms;
}
</style>
