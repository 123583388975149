import { has, get } from 'lodash';

const formatForIcon = (key, value, iconType) => {
  if (key == 'icon' && typeof value == 'string') {
    return value?.replace('{type}', iconType || 'regular') || value;
  }
  return value;
};

const filterEnabled = (key, value, masterObj) => {
  if (value != null && typeof value == 'object' && has(value, 'enabled')) {
    return value.enabled == null ? true : value.enabled;
  } else if (key == 'about_me_video') {
    return masterObj?.about_me_video_enabled;
  }
  return true;
};

const deepCopyFunction = (
  inObject,
  filterFn = () => true,
  formatFn = (k, v) => v,
  masterObj
) => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject;
  }
  const isArray = Array.isArray(inObject);
  const outObject = isArray ? [] : {};

  for (const key in inObject) {
    const value = inObject[key];
    if (filterFn(key, value, masterObj ? masterObj : inObject)) {
      const item = deepCopyFunction(
        formatFn(key, value),
        filterFn,
        formatFn,
        masterObj ? masterObj : inObject
      );
      if (isArray) outObject.push(item);
      else outObject[key] = item;
    }
  }

  return outObject;
};

const getIconType = (profile) => get(profile, 'iconStyle', 'regular');

const formatForSelfService = (profile, iconType, shouldFormat) => {
  if (shouldFormat) return profile;
  return deepCopyFunction(profile, filterEnabled, (key, value) =>
    formatForIcon(key, value, iconType)
  );
};

export {
  formatForSelfService,
  deepCopyFunction,
  formatForIcon,
  filterEnabled,
  getIconType
};
