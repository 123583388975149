<template>
  <head-short
    :details="details"
    :image-prefix="imagePrefix"
    :in-show-referrals="inShowReferrals"
    :globalPresets="globalPresets"
  >
    <template v-slot:sms>
      <a rel="nofollow"
        class="btn text-lg sm:text-2xl lg:text-lg leading-none ml-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center"
        v-on:click="
          captureEvent({
            value: getEmail,
            type: 'email',
            action: 'click',
            title: `email`
          })
        "
        :class="getTW('details-head-refer-icons')"
        :href="`mailto:${getEmail}`"
      >
        <i class="fal fa-envelope"></i>
      </a>
    </template>
  </head-short>
</template>
<script>
import { profileMixin } from '../scripts/index';
import headShort from './head-short.vue';

export default {
  props: ['inShowReferrals'],
  components: {
    headShort
  },
  mixins: [profileMixin]
};
</script>
