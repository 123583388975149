export default (
  inOption,
  state = {
    container: { clientWidth: 0 },
    sizeState: 0
  }
) => {
  const opt = {
    scale: (d) => d,
    ...inOption
  };
  const updateWidth = () => {
    state.sizeState = state.container.clientWidth;
  };

  window.addEventListener('resize', updateWidth);
  return {
    getSizeScaled: () => opt.scale(state.sizeState),
    getSize: () => state.sizeState,
    setContainer: (c) => {
      state.container = c;
    },
    state
  };
};
