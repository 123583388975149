import { isEmpty, isNil } from 'ramda';

const leadFormRegex = /<p>##(.*?)##<\/p>/g;
export default (leadFormData, profileEmail) => ({
  leadFormData: {
    leadFormData,
    profileEmail: profileEmail
  },
  isLeadForm: (info) => {
    return (
      !isNil(info) &&
      !isNil(info.match(leadFormRegex)) &&
      !isEmpty(info.match(leadFormRegex))
    );
  },
  /**
   *
   * @param {*} leadFormBody description from additional information
   * @returns Return object for lead form to use
   */
  getForm: (leadFormBody = '') => {
    const tags = leadFormBody.match(leadFormRegex);
    const stripTag = (tag) => tag.replace(/(<p>)|#|(<\/p>)/g, '');
    if (!tags) return { bodyText: leadFormBody, tags: [] };
    const bodyText = tags.reduce((acc, cur) => {
      return acc.replace(cur, `##${stripTag(cur)}##`);
    }, leadFormBody);
    return {
      bodyText,
      tags: tags.map((tag) => stripTag(tag))
    };
  },
  /**
   * @@{"tag":"iframe","src":"", "title":""}@@
   * @param {*} body description from additional information
   * @returns Returns body text that added in elements
   */
  buildElement: (body) => {
    let bodyText = body;
    const allElements = body ? body.match(/@@(.*?)@@/g) : [];
    if (allElements) {
      allElements.forEach((tag) => {
        const elementObject = JSON.parse(tag.replace(/(<p>)|@@|(<\/p>)/g, ''));
        if (elementObject.tag) {
          let newElement = `<${elementObject.tag} attr>innerHTML</${elementObject.tag}>`;
          for (const key in elementObject) {
            if (key != 'tag') {
              if (key == 'innerHTML') {
                newElement = newElement.replace(key, elementObject[key]);
              } else {
                newElement = newElement.replace(
                  'attr',
                  `${key}="${elementObject[key]}" attr`
                );
              }
            }
          }
          newElement = newElement.replace(' attr', '');
          newElement = newElement.replace('innerHTML', '');
          bodyText = bodyText.replace(tag, newElement);
        }
      });
    }
    return bodyText;
  }
});
