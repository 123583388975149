<script setup>
import { filter } from 'ramda';
import { computed, inject } from 'vue';
const props = defineProps({
  contacts: {
    type: Array,
    default: () => []
  },
  background: {
    type: String,
    default: ''
  }
});
const contactsFiltered = computed(() =>
  filter(
    (contact) => contact.contact_type == 'socialmedia' && contact.value,
    props.contacts
  )
);
const vHref = inject('vHref');
</script>
<template>
  <div class="social-media flex">
    <a
      v-for="(contact, i) in contactsFiltered"
      class="social-media-link flex items-center justify-center rounded-md"
      :key="i"
      :class="[
        background,
        {
          'ml-2': i,
          hasBackground: background
        }
      ]"
      :type="contact.contact_type"
      :title="contact.name"
      :analyticsTitle="contact.name"
      v-href="contact.value"
    >
      <i :class="contact.icon" class="p-0 m-0 leading-none"></i>
    </a>
  </div>
</template>
<style scoped>
.social-media-link.hasBackground {
  height: 2.4rem;
  width: 2.4rem;
}
.social-media-link {
  font-size: 15px;
}
@media only screen and (min-width: 768px) {
  .social-media-link.hasBackground {
    width: 2rem;
    height: 2rem;
  }
  .social-media-link {
    font-size: 15px;
  }
}
</style>
