<template>
  <v-modal
    :name="name"
    v-model="show"
    classes="modal-container"
    content-class="bio-popup modal-content bg-white relative rounded"
    :lock-scroll="false"
    :fit-parent="false"
  >
    <span
      class="
        absolute
        pa-6
        right-4
        top-2
        text-xl
        lg:text-2xl
        cursor-pointer
        text-gray-500
        bio-popup-icon
      "
      @click="close"
      ><i class="fal fa-times"></i
    ></span>
    <span
      class="
        absolute
        pa-6
        right-4
        bottom-2
        text-xl
        lg:text-2xl
        cursor-pointer
        text-gray-500
        bio-popup-icon
        bottom-bio-popup-icon
      "
      @click="close"
      ><i class="fal fa-times"></i
    ></span>
    <slot></slot>
  </v-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";

export default {
  components: { "v-modal": VueFinalModal },
  name: "pop-up",
  props: {
    name: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    close() {
      this.show = false;
    }
  }
};
</script>
