<template>
  <img
    v-if="image.url"
    :style="rounded ? 'border-radius:50%' : ''"
    :src="sizedImage('', image.url, maxWidth)"
    :srcset="srcset"
    :sizes="`(min-width: ${breakpoint}px) ${widthDesktop}, ${widthMobile}`"
    :alt="alt"
    :class="classes"
    loading="lazy"
    decoding="async"
  />
</template>
<script>
import { sizedImage } from '~/scripts/index';
export default {
  props: {
    image: Object,
    widthMobile: String,
    widthDesktop: String,
    rounded: Boolean,
    alt: String,
    classes: String,
    breakpoint: {
      type: Number,
      default: 1024
    }
  },
  methods: {
    sizedImage
  },
  computed: {
    srcset() {
      let srcs = this.widths.map(
        (width) =>
          `${sizedImage(
            this.prefix,
            this.image.url,
            Math.round(width)
          )} ${Math.round(width)}w`
      );
      return srcs.join(', ');
    },
    maxWidth() {
      //highest render width
      if (!this.widthDesktop.endsWith('px')) {
        return Math.min(this.image.width || 400, 400);
      } else {
        let wD = parseInt(this.widthDesktop);
        let wM = parseInt(this.widthMobile);
        if (!this.widthMobile.endsWith('px')) {
          wM = (wM / 100) * this.breakpoint;
        }
        return Math.min(this.image.width || 400, Math.max(wM, wD));
      }
    },
    widths() {
      let widths = [384, 640, 768, 1024];
      let fr = 1;
      if (this.widthMobile.endsWith('vw')) {
        fr = parseInt(this.widthMobile) / 100;
        widths = widths.map((w) => w * fr);
      } else {
        widths = [parseInt(this.widthMobile)];
      }
      widths = widths.filter((w) => w < this.maxWidth);
      widths.push(this.maxWidth);
      return widths;
    }
  }
};
</script>
