<script>
import DynamicForm from './dynamic-form.vue';
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DynamicForm
  },
  props: {
    template: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: '#000000'
    },
    headerColor: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    fColor() {
      // remove fColor and hColor once all templates are using string hex color
      if (this.fontColor) {
        if (this.fontColor.startsWith('#')) {
          return this.fontColor;
        } else {
          return JSON.parse(this.fontColor).hex;
        }
      }
      return '';
    },
    hColor() {
      if (this.fontColor) {
        if (this.headerColor.startsWith('#')) {
          return this.headerColor;
        } else {
          return JSON.parse(this.headerColor).hex;
        }
      }
      return '';
    }
  }
};
</script>
<template>
  <div
    class="dynamic-container"
    :style="{ '--fontColor': fColor, '--headerColor': hColor }"
    v-html="this.template"
  ></div>
</template>
<style scoped>
@import url('https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.4/dist/quill.snow.css');
.dynamic-container {
  color: var(--fontColor);
}

.dynamic-container h1,
.dynamic-container h2,
.dynamic-container h3,
.dynamic-container h4,
.dynamic-container h5,
.dynamic-container h6 {
  color: var(--headerColor);
}
</style>
