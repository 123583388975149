<script setup>
import { inject } from 'vue';
import contactLink from '@/components/contact-link.vue';
import callToAction from '@/components/contact-buttons/call-to-action.vue';
import { getClass, getKey } from '~/scripts';
const vHref = inject('vHref');

defineProps({
  contactButtonsHandler: {
    type: Array,
    default: () => []
  },
  showValue: {
    type: Boolean,
    default: false
  }
});

const contactStyling = `flex items-center flex-auto flex-grow details-contacts-section px-3 bg-white w-2/5 rounded-md h-12 lg:h-14 sm:h-16 pr-`;

const getContactStyling = (contact) =>
  contactStyling + getKey(contact) + ` ${getKey(contact)}-button`;
</script>
<template>
  <div class="contact-buttons flex flex-wrap">
    <contact-link
      v-for="(contact, i) in contactButtonsHandler.contacts"
      v-href="contact.value"
      as="a"
      :key="i"
      :analyticsTitle="contact.name"
      :link="contact.value"
      :type="contact.contact_type"
      :class="[getContactStyling(contact), getClass(contact)]"
      class="contact-button block"
    >
      <div
        class="contact-button-icon box-content text-xl mr-2 pr-2 flex w-6 justify-center"
        v-if="contact.icon"
      >
        <i :class="contact.icon"></i>
      </div>
      <div class="contact-button-body flex flex-col info overflow-hidden">
        <template v-if="showValue">
          <span
            class="details-contacts-section-title overflow-hidden overflow-ellipsis"
            :class="[
              {
                'opacity-50':
                  contact.contact_type == 'phone' ||
                  contact.contact_type == 'email'
              }
            ]"
            >{{ contact.name }}</span
          >
          <span
            class="details-contacts-section-value overflow-hidden overflow-ellipsis"
            v-if="
              contact.contact_type == 'phone' || contact.contact_type == 'email'
            "
            >{{ contact.value }}</span
          >
        </template>
        <span
          v-else
          class="contact-button-content-name overflow-hidden overflow-ellipsis"
        >
          {{ contact.name }}
        </span>
      </div>
    </contact-link>
    <call-to-action
      class="w-full main-action-button"
      :class="[
        getContactStyling({ key: 'contact-form' }),
        contactButtonsHandler.callToAction.key
      ]"
      :value="contactButtonsHandler.callToAction"
    />
  </div>
</template>
