import { pathOr, trim } from 'ramda';
import { chain, get } from 'lodash';
import DOMPurify from 'dompurify';

export const formatForSuperscript = (text) =>
  chain(text)
    .trim()
    .replace(/®/g, '<span class="superscript">®</span>')
    .replace(/™/g, '<span class="superscript">™</span>')
    .replace(/©/g, '<span class="superscript">©</span>')
    .value();

export default (profile) => {
  const formatForWhatsApp = (number = '') => {
    if (!number.length) return '';
    if (number[0] == '0')
      return ('27' + number.substring(1)).replace(/\s/g, '');
    return number.replace('+', '').replace(/\s/g, '');
  };

  const getContactDetail = (type, key, details) => {
    let result;

    result = details.find(
      (detail) =>
        detail.contact_type == type && pathOr('', ['key'], detail) == key
    );

    return result && result.value ? result.value : '';
  };

  return {
    get fullName() {
      return trim(
        `${pathOr('', ['person', 'first_name'], profile)} ${pathOr(
          '',
          ['person', 'last_name'],
          profile
        )}`
      );
    },
    get fullNameHTML() {
      return formatForSuperscript(
        DOMPurify.sanitize(
          `${get(profile, 'person.first_name', '')} ${get(
            profile,
            'person.last_name',
            ''
          )}`
        )
      );
    },
    get getNumber() {
      if (profile.contacts) {
        const temp = getContactDetail('phone', 'mobile', profile.contacts);
        return temp.length
          ? temp
          : getContactDetail('phone', 'office', profile.contacts);
      }
      return '';
    },
    get whatsAppNumber() {
      if (profile.contacts) {
        return formatForWhatsApp(
          getContactDetail('phone', 'whatsapp', profile.contacts) ||
            this.getNumber
        );
      }
      return '';
    },
    get getEmail() {
      if (profile.contacts) {
        return getContactDetail('email', 'email', profile.contacts);
      }
      return '';
    },
    get jobTitle() {
      return pathOr('', ['biography', 'job_title'], profile);
    },
    get jobTitleHTML() {
      return formatForSuperscript(
        DOMPurify.sanitize(pathOr('', ['biography', 'job_title'], profile))
      );
    },
    get profileImage() {
      return {
        url: pathOr('', ['person', 'profile_gallery', '0', 'url'], profile),
        width: pathOr(400, ['person', 'profile_gallery', '0', 'width'], profile)
      };
    },
    get titleSize() {
      return pathOr(0, ['title_size'], profile);
    },
    get subtitleSize() {
      return pathOr(0, ['subtitle_size'], profile);
    },
    get referralMessage() {
      return pathOr('', ['referral_message'], profile);
    }
  };
};
