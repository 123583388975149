import { keys, reduce, defaultTo, mergeAll } from 'ramda';
import ipify from 'ipify2';

const defaultVal = defaultTo('');

const generateLeadFormHTML = (fields, emailInfo, browserId, sessionId) => {
  const getBodyRow = (label, value) => `
  <tr style="width:100%;">
      <td align="left" style="background-color: #ebe9eb; padding: 10px 10px; font-family: Arial, Verdana, 'sans-serif'; font-size: 16px; font-weight: 300; line-height: 22px; color:#333333;">${label}</td>
  </tr>
  <tr style="padding: 5px 5px; font-family: Arial, Verdana, 'sans-serif'; width: 100%;">
      <td align="left" style="padding: 10px 10px; font-family: Arial, Verdana, 'sans-serif'; font-size: 14px; color:#666666;">${value}</td>
  </tr>
`;
  const formHeader = reduce(
    (header, field) => header.replace(`@${field.field_name}@`, field.value),
    `
      <tr style="width:90%;">
          <th colspan="2" align="left" style="padding: 10px 10px 30px 10px; font-family: Arial, Verdana, 'sans-serif'; font-size: 20px; font-weight: 300; color:#333333;">${emailInfo.table_heading}</th>
      </tr>
    `,
    fields
  );

  const formData = reduce(
    (acc, item) => {
      return (
        acc +
        getBodyRow(
          item.label,
          item.type == 'checkbox' ? (item.value ? 'Yes' : 'No') : item.value
        )
      ).replace('{{HEADER-DATA}}', formHeader);
    },
    '',
    fields
  );

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <title></title>
    </head>
    <body>
        <div>
            <table align="center" cellpadding="0" cellspacing="10" style="width:90%; border: 1px solid #ebe9eb; align-content: center;">
                {{HEADER-DATA}}
                {{FORM-DATA}}
                ${getBodyRow('Form ID', browserId)}
                ${getBodyRow('Instance ID', sessionId)}
            </table>
        </div>
    </body>
    </html>
    `
    .replace('{{HEADER-DATA}}', formHeader)
    .replace('{{FORM-DATA}}', formData);
};

const ANALYTICS_ACTIONS = Object.freeze({
  open: 'open', // type : ['modal', 'dropdown', 'share-menu', 'lead-form']
  scroll: 'scroll',
  page_view: 'page_view',
  share: 'share', // type : [ medium, 'sms', 'email' ]
  'view-media': 'view-media', // type : [ 'image', 'map', 'video' ]
  click: 'click', // type: ['socialmedia', 'sms', 'email', 'copy-link', 'accept-cookies', 'save-to-contacts', 'url', 'phone'],
  submit: 'submit' // type: ['lead-form'],
});

const ANALYTICS_TYPE = Object.freeze({
  modal: 'modal', // open
  testimonial: 'testimonial', // open
  dropdown: 'dropdown', // open
  section: 'section', // scroll
  'share-menu': 'share-menu', // open
  email: 'email', // click, share
  sms: 'sms', // click, share
  page_view: 'page_view', // page_view
  twitter: 'twitter', // share
  x: 'x', // share
  'call-to-action': 'call-to-action', // click
  facebook: 'facebook', // share
  linkedin: 'linkedin', // share
  'pwa-install': 'pwa-install', // click
  instagram: 'instagram',
  whatsapp: 'whatsapp', // click, share
  qr_code: 'qr_code', // view-media
  image: 'image', // view-media
  map: 'map', // view-media
  phone: 'phone', // click
  socialmedia: 'socialmedia', // click
  video: 'video', // view-media
  'copy-link': 'copy-link', // click
  'accept-cookies': 'accept-cookies', // click
  'save-to-contacts': 'save-to-contacts', // click
  url: 'url', // click
  'share-via': 'share-via', // share, submit
  'lead-form': 'lead-form' // open
});

const firebaseHandler = (
  db,
  collection,
  isUnique,
  browserId,
  sessionId,
  referer,
  profileId,
  companyId,
  params = {}
) => {
  async function captureEvent({
    alias,
    subdomain,
    domain,
    action, // click, view, share, page_view
    title,
    value, // ex. url
    type
  }) {
    try {
      const analytics = {
        alias: defaultVal(alias),
        subdomain: defaultVal(subdomain),
        domain: defaultVal(domain),
        action: defaultVal(action),
        value: defaultVal(value),
        profileId: defaultVal(profileId),
        companyId: defaultVal(companyId),
        title: defaultVal(title),
        type: defaultVal(type),
        userAgent: navigator.userAgent,
        createdAt: new Date().getTime(),
        browserId,
        sessionId,
        referer,
        isUnique: isUnique || false,
        ...params
      };

      try {
        const ipAddress = await ipify.ipv64();
        await db
          .collection(collection)
          .add(mergeAll([analytics, { ipAddress }]));
      } catch (error) {
        await db.collection(collection).add(analytics);
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  function submitFormToDB(collection, mailToSender, cc) {
    return (
      events = {
        onSuccess: () => {},
        onError: () => {},
        onFinally: () => {}
      },
      formData = {
        profileEmail: '',
        emailInfo: { table_heading: '', from: '', subject: '' },
        inputFields: [],
        formId: ''
      }
    ) =>
      db
        .collection(collection)
        .add({
          to: mailToSender ? formData.emailInfo.from : formData.profileEmail,
          message: {
            subject: formData.emailInfo.subject,
            html: generateLeadFormHTML(
              formData.inputFields,
              formData.emailInfo,
              browserId,
              sessionId
            )
          },
          browserId,
          sessionId,
          profileId,
          companyId,
          formId: formData.formId,
          data: formData.inputFields,
          cc
        })
        .then(events.onSuccess)
        .catch(events.onError)
        .finally(events.onFinally);
  }

  const getCaptureEventFunction = (profileDetails) => {
    return (eventDetails) =>
      captureEvent(mergeAll([profileDetails, eventDetails]));
  };

  return {
    getCaptureEventFunction,
    submitFormToDB
  };
};
const firebaseHandlerProxy = (
  isUnique,
  browserId,
  sessionId,
  referer,
  profileId,
  companyId,
  params
) =>
  keys(firebaseHandler()).reduce(
    (acc, key) =>
      mergeAll([
        acc,
        {
          [key]: (logPC) =>
            key == 'getCaptureEventFunction'
              ? (logAction) =>
                  console.log(
                    {
                      ...logPC,
                      isUnique,
                      browserId,
                      sessionId,
                      referer,
                      profileId,
                      companyId,
                      ...params
                    },
                    logAction
                  )
              : ({ onSuccess, onFinally }, submitInfo) => {
                  console.log(submitInfo);
                  onSuccess();
                  onFinally();
                }
        }
      ]),
    {}
  );

const QUERY_FIELD_PRESETS = {
  r: 'query_referer'
};
const QUERY_VALUE_PRESETS = {
  f: 'Facebook',
  t: 'Twitter',
  l: 'Linkedin',
  w: 'Whatsapp',
  s: 'SMS',
  e: 'Email'
};
export {
  QUERY_FIELD_PRESETS,
  QUERY_VALUE_PRESETS,
  ANALYTICS_ACTIONS,
  ANALYTICS_TYPE,
  firebaseHandler,
  firebaseHandlerProxy
};
