import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue';

createApp({
  components: {
    ProfileMe: App
  },
  data() {
    try {
      const profileData = window.profile;
      const leadFormData = window.leadForms || [];
      const globalPresets = window.globalPresets;
      const enableAnalytics = window.enableAnalytics;
      const productionCollections = window.productionCollections;

      const { profileAlias, id, company } = profileData;

      return {
        enableAnalytics,
        productionCollections,
        leadFormData,
        content: profileData,
        profileAlias: profileAlias,
        globalPresets,
        profileId: id,
        companyId: company?.id,
        subdomain: company?.customDomainUrl || company?.name
      };
    } catch (error) {
      console.log(error.message);
      return {};
    }
  },
  template: `
    <profile-me 
      :enable-analytics="enableAnalytics"
      :production-collections="productionCollections"
      :lead-form-data="leadFormData"
      :profile-id="profileId" 
      :alias="profileAlias" 
      :company-id="companyId" 
      :subdomain="subdomain" 
      :details="content" 
      :global-presets="globalPresets"
      :ask-cookie-consent="true"
    ></profile-me>
  `
}).mount('#app');
