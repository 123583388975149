export const props = {
  enableAnalytics: {
    type: Boolean,
    default: false
  },
  productionCollections: {
    type: Boolean,
    default: false
  },
  askCookieConsent: {
    type: Boolean,
    default: false
  },
  enablePwa: {
    type: Boolean,
    default: true
  },
  leadFormData: {
    type: Array,
    default: () => []
  },
  profileId: {
    type: String,
    default: '0'
  },
  version: {
    type: String,
    default: '1.0.0'
  },
  alias: {
    type: String,
    default: ''
  },
  companyId: {
    type: String,
    default: ''
  },
  subdomain: {
    type: String,
    default: ''
  },
  privacyUrl: {
    type: String
  },
  supportUrl: {
    type: String
  },
  previewMode: {
    type: Boolean,
    default: false
  },
  details: {
    type: Object,
    default: () => ({
      profile_styling: {
        fontColor: '',
        headerColor: '',
        layout: 'short',
        cssText: ''
      }
    })
  },
  globalPresets: {
    type: Object,
    default: () => ({
      globalCssPlug: '',
      globalStylePresets: []
    })
  }
};

export const globalPresets = {
  globalCssPlug: ``,
  published_at: '2021-06-18T09:55:47.933Z',
  created_at: '2021-06-15T11:57:07.431Z',
  updated_at: '2022-05-18T12:12:23.222Z',
  globalStylePresets: [
    { name: 'header', styling: 'h-16 px-6' },
    { name: 'details', styling: 'mb-5' },
    { name: 'details-head-picture', styling: 'mr-6' },
    { name: 'details-head-title', styling: 'mb-2 lg:mb-0' },
    { name: 'details-head-subtitle', styling: '' },
    { name: 'details-head-refer-icons', styling: 'rounded-full' },
    { name: 'details-contacts-body', styling: 'pl-6 pr-3 py-5' },
    {
      name: 'details-contacts-section',
      styling: ' px-3 mr-3 mb-3 lg:mr-3'
    },
    { name: 'details-footer', styling: 'pl-6' },
    { name: 'bio', styling: 'mb-5 mx-6' },
    { name: 'bio-collapse', styling: 'xl:pr-0 border' },
    { name: 'bio-collapse-head', styling: 'px-2' },
    { name: 'bio-collapse-body', styling: 'px-4 lg:px-4' },
    { name: 'referrals-bottom', styling: 'px-6' },
    { name: 'referrals-bottom-section', styling: 'py-3 px-2' },
    { name: 'referrals-bottom-section-icon', styling: 'mr-2 text-lg' },
    { name: 'logo-image', styling: 'w-3/4 lg:w-2/4  xl:ml-12 lg:mr-6' },
    { name: 'social-media-icons', styling: 'mx-1 w-12 h-12' },
    { name: 'footer', styling: 'text-center py-6 lg:py-6 px-4' },
    { name: 'footer-content', styling: 'leading-4 border-b pb-4' },
    { name: 'preset-height', styling: 'h-12 lg:h-14 sm:h-16' },
    { name: 'preset-bottom-margin', styling: 'mb-6' },
    { name: 'preset-icon-margin-right', styling: 'mr-2' },
    {
      name: 'details-head-tall-picture',
      styling: 'rounded-full mb-4 z-10 -mt-10 sm:-mt-14 md:-mt-16 lg:-mt-10'
    },
    { name: 'details-head-tall-refer-icons', styling: 'rounded-full' },
    {
      name: 'bio-collapse-border',
      styling: 'border-gray-100 lg:border-gray-200'
    },
    { name: 'footer-color', styling: 'bg-gray-200 text-gray-600' },
    { name: 'social-media', styling: 'xl:ml-12 lg:mr-6' },
    {
      id: 31,
      name: 'bio-collapse-head-icon',
      styling: 'text-lg lg:ml-2 lg:mr-8'
    },
    { name: 'details-footer-content', styling: 'w-full' },
    { name: 'endorsements', styling: 'justify-center' }
  ]
};
