<script setup>
import { inject } from 'vue';
const vHref = inject('vHref');

defineProps({
  footerHandler: {
    type: Object,
    default: () => {}
  }
});
</script>
<template>
  <div class="w-full text-center py-6 px-4 footer footer-links">
    <p class="w-full leading-none">
      <a
        v-for="(link, i) in footerHandler.footerLinks"
        v-href="link.value"
        :type="link.contact_type"
        :class="link.key"
        :title="link.name"
        :analyticsTitle="link.name"
        :key="i"
        >{{ link.name }} |
      </a>
      <span
        v-html="footerHandler.pageFooter"
        class="leading-none footer-disclaimer"
      >
      </span>
      <span class="profile-me-plug">
        | Powered by
        <a href="https://hello.profileme.app/support">ProfileMe©</a>
      </span>
    </p>
  </div>
</template>
<style>
.footer-disclaimer p {
  display: inline-block;
}
</style>
