<template>
  <div class="banner-carousel">
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :autoplay="{
        delay: 7000,
        disableOnInteraction: false
      }"
      :modules="modules"
      class="mySwiper"
    >
      <slot />
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";

export default {
  components: {
    Swiper
  },
  setup() {
    return {
      modules: [Autoplay]
    };
  }
};
</script>
<style>
.banner-carousel .swiper {
  width: 100%;
  height: 100%;
}

.banner-carousel .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.banner-carousel .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>