<script setup>
import { inject } from 'vue';
defineProps({
  showSaveButton: { type: Boolean, default: false },
  buttonLayout: { type: Boolean, default: false }
});
const emit = defineEmits(['saveClick']);
const openReferrals = inject('openReferrals');

const shareButtonsStyling = `flex items-center cursor-pointer rounded-md px-4 h-12 w-6/12 text-sm lg:h-14 sm:h-16 bg-gray-800 text-white bg-brand`;
</script>
<template>
  <div
    v-if="buttonLayout"
    class="sharing pb-9 flex justify-between w-full md:hidden"
  >
    <button
      class="add-to-contacts-or-save-button mr-1"
      :class="[
        shareButtonsStyling,
        {
          'add-to-contacts-btn': !showSaveButton
        },
        {
          'save-app-btn': showSaveButton
        }
      ]"
      @click="emit('saveClick')"
    >
      <i
        :class="[
          {
            'far fa-address-book': !showSaveButton
          },
          {
            'fa-solid fa-download': showSaveButton
          }
        ]"
      >
      </i>
      <span class="ml-4 text-left">{{
        showSaveButton ? 'Save Profile' : 'Add To Contacts'
      }}</span>
    </button>
    <button
      class="share-button"
      :class="shareButtonsStyling"
      @click="openReferrals"
    >
      <i class="fad fa-share-square"> </i> <span class="ml-4">Share</span>
    </button>
  </div>
  <div v-else class="flex">
    <div
      class="add-to-contacts flex items-center cursor-pointer"
      :class="[
        {
          'add-to-contacts-btn': !showSaveButton
        },
        {
          'save-app-btn': showSaveButton
        }
      ]"
      @click="emit('saveClick')"
    >
      <i
        class="text-2xl mr-4 w-8 leading-none text-center md:mr-4"
        :class="[
          {
            'far fa-address-book': !showSaveButton
          },
          {
            'fa-solid fa-download': showSaveButton
          }
        ]"
      >
      </i>
      <span class="hidden leading-none md:inline">{{
        showSaveButton ? 'Save Profile' : 'Add To Contacts'
      }}</span>
    </div>
    <slot name="separator">
      <div class="px-2" :class="separatorClass"></div
    ></slot>
    <div
      @click="openReferrals"
      class="share flex items-center cursor-pointer md:mr-8"
    >
      <i
        class="fa fa-share-square text-2xl md:text-xl md:mr-4"
        :class="iconStyle"
      >
      </i>
      <span class="hidden md:inline">Share</span>
    </div>
  </div>
</template>
