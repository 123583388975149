<script setup>
import { replace } from 'ramda';
import ratioContainer from '@/components/ratio-container.vue';
import renderComponentFromString from '@/components/render-component-from-string.vue';
import dynamic from '@/components/dynamic-component.vue';

const formatEscape = (str) => replace(/\\"/g, '"', str);
defineProps({
  description: {
    type: String,
    default: ''
  },
  aboutMeVideo: {
    type: String,
    default: ''
  },
  leadFormHandler: {
    type: Object,
    default: () => {}
  }
});
</script>
<template>
  <article
    v-if="aboutMeVideo || description"
    class="flex justify-center flex-col md:flex-row"
  >
    <template v-if="aboutMeVideo && aboutMeVideo.length">
      <ratio-container class="md:hidden" :width="'100%'" :ratio="0.56">
        <div
          v-html="formatEscape(aboutMeVideo)"
          class="about-me-video overflow-hidden h-full"
        ></div>
      </ratio-container>
      <div
        v-html="formatEscape(aboutMeVideo)"
        class="hidden md:block about-me-video overflow-hidden rounded-3xl h-96 md:w-5/12 md:mr-16"
      ></div>
    </template>
    <div v-if="description" class="bio-description flex-1 rich-text ql-editor">
      <render-component-from-string
        v-if="leadFormHandler.isLeadForm(description)"
        :inData="leadFormHandler.leadFormData"
        :title="'Get in Touch / Request a Call Back'"
        :template="leadFormHandler.getForm(description)"
      />
      <dynamic
        v-else
        :template="leadFormHandler.buildElement(description)"
      ></dynamic>
    </div>
  </article>
</template>
