<template>
  <div class="flex relative referrals">
    <notifications width="100px" position="bottom left" />
    <div class="flex">
      <slot v-bind:builders="{ buildReferral, formatLink, referrals }"></slot>
      <button
        class="leading-none flex items-center justify-center refer details-head-refer-icons"
        :class="iconStyling"
        @click="openReferrals"
      >
        <i class="custom-referral-icon"></i>
      </button>
    </div>
    <div
      class="referral-body flex fixed top-0 right-0 xl:pt-20 bg-gray-600 w-full h-full flex-col justify-center xl:justify-start z-50"
      v-if="showReferrals"
    >
      <button
        class="text-white font-bold px-4 rounded absolute top-2 right-2 referral-exit"
        @click="closeReferrals"
      >
        X
      </button>
      <div
        class="referral-row border-white border-b text-white cursor-pointer copy-link"
        @click="copyLink"
      >
        <a
          rel="nofollow"
          class="text-lg xl:text-xl flex items-center content-center leading-none py-3"
        >
          <div
            class="mr-8 icon text-white px-8 border-r border-white leading-none flex items-center"
          >
            <i
              class="text-lg xl:text-xl fa fa-link w-4"
              color='{"hex": "#FFF"}'
            ></i>
          </div>
          <div>Copy Link</div>
        </a>
      </div>
      <div
        v-if="isMobile"
        class="referral-row border-white border-b text-white cursor-pointer share-via"
        @click="shareWith"
      >
        <a
          rel="nofollow"
          class="text-lg xl:text-xl flex items-center content-center leading-none py-3"
        >
          <div
            class="mr-8 icon text-white px-8 border-r border-white leading-none flex items-center"
          >
            <i
              class="text-lg xl:text-xl fad fa-share-square w-4"
              color='{"hex": "#FFF"}'
            ></i>
          </div>
          <div>Share via</div>
        </a>
      </div>
      <div
        v-for="(ref, i) in referrals"
        class="referral-row border-white border-b text-white"
        :class="titles[i].toLowerCase()"
        :key="i"
        @click="
          captureEvent({
            value: buildReferral(ref),
            type: titles[i].toLowerCase(),
            action: 'share',
            title: `${titles[i]}`
          })
        "
      >
        <a
          rel="nofollow"
          class="text-lg xl:text-xl flex items-center content-center leading-none py-3"
          :href="buildReferral(ref)"
          target="_blank"
        >
          <div
            class="mr-8 icon text-white px-8 border-r border-white leading-none flex items-center"
          >
            <i
              class="text-lg xl:text-xl w-4"
              :class="icons[i]"
              color='{"hex": "#FFF"}'
            ></i>
          </div>
          <div>{{ titles[i] }}</div>
        </a>
      </div>
      <div
        class="referral-row border-white border-b text-white cursor-pointer add-to-contacts"
        @click="downloadContact"
      >
        <a
          class="text-lg xl:text-xl flex items-center content-center leading-none py-3"
        >
          <div
            class="mr-8 icon text-white px-8 border-r border-white leading-none flex items-center"
          >
            <i
              class="text-lg xl:text-xl far fa-address-book w-4"
              color='{"hex": "#FFF"}'
            ></i>
          </div>
          <div>Add To Contacts</div>
        </a>
      </div>
      <div
        class="referral-row border-white border-b text-white cursor-pointer save-profile"
        @click="saveClick(true)"
      >
        <a
          class="text-lg xl:text-xl flex items-center content-center leading-none py-3"
        >
          <div
            class="mr-8 icon text-white px-8 border-r border-white leading-none flex items-center"
          >
            <i
              class="text-lg xl:text-xl fad fa-download w-4"
              color='{"hex": "#FFF"}'
            ></i>
          </div>
          <div>Save Profile</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { isIos, isMobile } from '~/functions/wrappers/save-and-share-wrapper';
import { formatLink } from '../scripts/index';
import { head } from 'lodash';
export default {
  name: 'referrals-comp',
  emit: ['close', 'saveClick', 'downloadContact'],
  inject: ['notify', 'captureEvent'],
  props: {
    show: {
      default: false,
      type: Boolean
    },
    message: {
      default: '',
      type: String
    },
    profileAlias: {
      default: '',
      type: String
    },
    profileId: {
      default: '',
      type: String
    },
    companyName: {
      default: '',
      type: String
    },
    fullName: {
      default: '',
      type: String
    },
    iconStyling: {
      default: '',
      type: String
    },
    showSaveButton: {
      default: false,
      type: Boolean
    }
  },
  data() {
    const URI = head(document.URL.split('?'));
    const facebookLink = isMobile()
      ? 'http://m.facebook.com/sharer.php'
      : 'https://www.facebook.com/sharer/sharer.php';
    return {
      referrals: [
        'whatsapp://send?text=@message-bold@url%3Fr%3Dw',
        'sms:?body=@message-bold@url%3Fr%3Ds',
        'mailto:?subject=@fullName&body=@message @url%3Fr%3De',
        facebookLink + '?u=@url%3Fr%3Df',
        'https://twitter.com/intent/tweet?url=@url%3Fr%3Dt&text=@message',
        'https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@message&summary=&source='
      ],
      titles: ['Whatsapp', 'SMS', 'Email', 'Facebook', 'X', 'Linkedin'],
      icons: [
        'fab fa-whatsapp',
        'fa fa-commenting-o',
        'fa fa-envelope-o',
        'fa fa-facebook',
        'fab fa-x-twitter',
        'fa fa-linkedin'
      ],
      showReferrals: this.show,
      URI
    };
  },
  computed: {
    isMobile
  },
  methods: {
    formatLink: formatLink,
    async shareWith() {
      if (this.isMobile) {
        await navigator.share({
          title: this.fullName,
          text: this.message || '',
          url: this.URI
        });
        this.captureEvent({
          value: '',
          type: 'share-via',
          action: 'share',
          title: 'Share via'
        });
      }
    },
    buildReferral(ref) {
      const formattedRef = ref
        .split(`@message-bold`)
        .join(this.message ? `*${this.message}* ` : '')
        .split(`@message`)
        .join(this.message || '')
        .split(`@email`)
        .join(this.email)
        .split(`@number`)
        .join(this.number)
        .split(`@url`)
        .join(this.URI)
        .split(`@fullName`)
        .join(this.fullName);
      if (isIos()) {
        return formattedRef.split('?body=').join('&body=').split('*').join('');
      }
      return formattedRef;
    },
    copyLink() {
      navigator.clipboard.writeText(this.URI);

      this.captureEvent({
        value: this.URI,
        type: 'copy-link',
        action: 'click',
        title: ''
      });
      this.notify('Link Copied');
    },
    openReferrals() {
      this.showReferrals = true;
    },
    closeReferrals() {
      this.showReferrals = false;
      this.$emit('close');
    },
    saveClick(shouldSave) {
      this.$emit('saveClick', shouldSave);
    },
    downloadContact() {
      this.$emit('downloadContact');
    }
  },
  watch: {
    show(newVal) {
      this.showReferrals = newVal;
    },
    showReferrals(newVal) {
      if (newVal)
        this.captureEvent({
          value: '',
          type: 'share-menu',
          action: 'open',
          title: ''
        });
    }
  }
};
</script>

<style>
div.toast {
  left: 0;
  right: initial;
}

div.toast > .body {
  position: relative;
  font-size: initial;
  margin: 0 1em 1em 1em;
  padding: 0.8em;
  word-wrap: break-word;
  border-radius: 0;
  background: #68cd86;
  border-left: #42a85f solid 5px;
  pointer-events: all;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
