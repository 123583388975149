export default (profile) => {
  return {
    get footerLinks() {
      return profile.footerLinks;
    },
    get pageFooter() {
      return profile.page_footer;
    }
  };
};
