<template>
  <div
    class="head-tall flex flex-col w-full items-center text-center"
    :class="getTW('details-head')"
    ref="headContainer"
  >
    <div
      class="overflow-hidden h-full flex justify-center lg:w-5/12"
      :class="getTW('details-head-picture')"
      v-if="deepIf(['person', 'profile_gallery', '0'])"
    >
      <responsive-image
        :prefix="imagePrefix"
        :image="details.person.profile_gallery[0]"
        :full-name="getName"
        :widthMobile="'33vw'"
        :widthDesktop="'100%'"
        :alt="getName"
      />
    </div>
    <h1
      v-if="deepIf(['person'])"
      class="title"
      :style="titleSize"
      :class="getTW('details-head-title')"
      v-html="
        formatForSuperscript(
          sanitize(details.person.first_name + ' ' + details.person.last_name)
        )
      "
    >
    </h1>
    <h2
      v-if="deepIf(['biography', 'job_title'])"
      class="details-head-subtitle"
      :style="subtitleSize"
      :class="getTW('details-head-subtitle')"
      v-html="formatForSuperscript(sanitize(details.biography.job_title))"
    >
    </h2>
    <referrals
      v-if="deepIf(['person'])"
      class="py-4"
      :message="details.referral_message"
      :email="getEmail"
      :number="getNumber"
      :profileId="details.id"
      :companyName="details.company.name"
      :profileAlias="details.profileAlias"
      :show="inShowReferrals"
      :full-name="details.person.firstName + ' ' + details.person.lastName"
      :iconStyling="
        'text-3xl leading-none mx-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center ' +
        getTW('details-head-refer-icons')
      "
      :showSaveButton="pwaHandler.showSaveButton"
      @saveClick="pwaHandler.handelSaveClick"
      @downloadContact="pwaHandler.vcfDownloadUrl"
    >
      <template v-slot:default>
        <a
          rel="nofollow"
          class="btn text-2xl leading-none mx-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center referral-whatsapp"
          v-on:click="
            captureEvent({
              value: getNumber,
              action: 'click',
              type: 'whatsapp',
              title: `whatsapp`
            })
          "
          :class="getTW('details-head-refer-icons')"
          :href="`https://api.whatsapp.com/send?phone=${getNumber?.replace(
            '+',
            ''
          )}`"
          target="_blank"
        >
          <i class="fab fa-whatsapp"></i>
        </a>
        <a
          rel="nofollow"
          class="btn text-lg sm:text-xl lg:text-lg leading-none mx-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center referral-contact"
          :class="getTW('details-head-refer-icons')"
          v-on:click="
            vcfDownloadUrl(details);
            captureEvent({
              action: 'click',
              type: 'save-to-contacts',
              title: ''
            });
          "
        >
          <i class="far fa-address-book"></i>
        </a>
        <a
          rel="nofollow"
          class="btn text-lg sm:text-2xl lg:text-lg leading-none ml-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center referral-email"
          v-on:click="
            captureEvent({
              value: getEmail,
              action: 'click',
              type: 'email',
              title: `email`
            })
          "
          :class="getTW('details-head-refer-icons')"
          :href="`mailto:${getEmail}`"
        >
          <i class="fal fa-envelope"></i>
        </a>
      </template>
    </referrals>
  </div>
</template>
<script>
import head from './head';

export default head();
</script>
