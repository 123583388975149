<template>
  <div class="testimonial flex flex-col h-full cursor-pointer">
    <div class="star-color mb-3 flex">
      <div
        v-for="(i, index) of 5"
        :key="index"
        class="relative w-5 h-4"
      >
        <i
          class="absolute left-0"
          :class="{
            'fa-solid': showStarByRating(index),
            'fa-regular': !showStarByRating(index),
            'fa-star': !isHalfRating(index, i),
            'fa-star-half': isHalfRating(index, i)
          }"
        ></i>
        <i
          class="fa-star fa-regular absolute left-0"
          v-if="isHalfRating(index, i)"
        ></i>
      </div>
    </div>
    <h5 class="testimonial-title text-lg whitespace-nowrap overflow-ellipsis overflow-hidden mb-3">
      {{ value.title }}
    </h5>
    <p
      ref="testimonialBody"
      class="testimonial-body text-sm review relative overflow-hidden"
      :class="{
        'h-20 short': limit,
        'mb-3': !limit
      }"
    >
      “{{ value.review }}”
    </p>
    <span
      class="read-more text-sm mb-3"
      :class="{ hidden: !limit, 'opacity-0': !showMore }"
    >
      read more...
    </span>
    <p class="testimonial-meta-data text-gray-700 text-sm mb-3">
      <span>{{ value.author }}</span> - <span>{{ value.date }}</span>
    </p>
  </div>
</template>
<script>
import { get } from "lodash";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        title: "",
        review: "",
        author: "",
        date: "",
        rating: 5
      })
    },
    limit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    showMore() {
      if (!this.isMounted)
        return 0;
      return (get(this.$refs, "testimonialBody.offsetHeight", 0) + 5) < get(this.$refs, "testimonialBody.scrollHeight", 0)
    }
  },
  methods: {
    showStarByRating(index) {
      const currentRating = ((this.value.rating || 5) * 10) / 2;
      return currentRating > index;
    },
    isHalfRating(index, count) {
      const currentRating = ((this.value.rating || 5) * 10) / 2;
      return (
        this.showStarByRating(index) &&
        currentRating < count &&
        (currentRating * 2) % 2 != 0
      );
    }
  }
};
</script>
<style scoped>
.star-color i {
  color: #fec24d;
  margin-right: 5px;
}
</style>
