<script setup>
import { inject } from 'vue';
import contactLink from '@/components/contact-link.vue';

const openModal = inject('openModal');
const captureEvent = inject('captureEvent');

defineProps({
  value: {
    type: Object,
    default: () => ({
      icon: '',
      link: '',
      analyticsType: '',
      type: '',
      content: '',
      key: ''
    })
  }
});
const openModalIfType = (type, value, name) => {
  if (type == 'modal' || type == 'lead-form') {
    openModal(value);
  } else {
    captureEvent({
      action: 'click',
      type: type,
      value: value,
      title: name
    });
  }
};
</script>
<template>
  <contact-link
    @clicked="openModalIfType(value.analyticsType, value.link, value.content)"
    :link="value.link"
    :type="value.type"
    as="a"
    class="contact-button lead-form-call-to-action cursor-pointer bg-brand text-left"
  >
    <span class="spinners absolute"></span>
    <span class="spinners absolute"></span>
    <span class="spinners absolute"></span>
    <span class="spinners absolute"></span>
    <div
      class="contact-button-icon box-content text-xl mr-2 pr-2 flex w-6 justify-center"
    >
      <i :class="value.icon"></i>
    </div>
    <div class="contact-button-body flex flex-col info overflow-hidden">
      <span class="overflow-hidden overflow-ellipsis">
        {{ value.content }}
      </span>
    </div>
  </contact-link>
</template>
