import { values, mapObjIndexed, includes, join, reduce } from 'ramda';

const isGradient = (mode) => {
  return mode == 'linear' || mode == 'radial' || mode == 'gradient';
};
const getSolidSetup = (mode, css) => {
  if (mode == 'solid') return css;
  return '';
};

const getGradientSetup = (mode, selector, prepend = '') => {
  if (isGradient(mode))
    return `
  ${prepend}

  ${selector}
  {
    background-size: 100%;
    background-repeat: repeat;
    padding: 1px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  } 
  `;
  return '';
};
const getCssSnippets = (contactKeys) => {
  const contactCssSnippets = reduce(
    (acc, cur) => ({
      ['contact-button-font-color-' + cur]: (mode, key) => {
        return `
    .pr-${key}.contact-button .contact-button-body span,
    .pr-${key}.contact-button .contact-button-icon i {
      background-image: var(--contact-button-font-color-${key});
      color: var(--contact-button-font-color-${key});
    }
    ${getGradientSetup(
      mode,
      `.pr-${key}.contact-button .contact-button-body span,
      .pr-${key}.contact-button .contact-button-icon i`
    )}
    `;
      },
      ['contact-button-background-color-' + cur]: (mode, key) => {
        return `
    .pr-${key}.contact-button {
      background-color: var(--contact-button-background-color-${key});
      background-image: var(--contact-button-background-color-${key});
    }
    `;
      },
      ...acc
    }),
    {}
  )(contactKeys);

  return {
    'main-action-button-background-color': () => {
      return `
  .main-action-button,
  .add-to-contacts-or-save-button {
    background-image: var(--main-action-button-background-color);
    background-color: var(--main-action-button-background-color);
  }
`;
    },
    'main-action-button-color': (mode) => {
      return `
    ${getSolidSetup(
      mode,
      '.main-action-button i,.main-action-button span, .add-to-contacts-or-save-button i, .add-to-contacts-or-save-button span { color: var(--main-action-button-color); }'
    )}

  ${getGradientSetup(
    mode,
    '.main-action-button i,.main-action-button span, .add-to-contacts-or-save-button i, .add-to-contacts-or-save-button span',
    '.main-action-button i,.main-action-button span, .add-to-contacts-or-save-button i, .add-to-contacts-or-save-button span { background-image: var(--main-action-button-color); }'
  )}
`;
    },
    'background-color': (mode) => {
      return `
  .main-container-custom,
  .main-container {
    background-image: var(--background-color);
    background-color: var(--background-color);
  }

  .social-media.mobile .social-media-link,
  .dt-header .social-media-link {
    background-image: var(--background-color);
    background-color: var(--background-color);
  }

  .share-button {
    background-image: var(--background-color);
    background-color: var(--background-color);
  }

  .footer-container  {
    background-image: var(--background-color);
    background-color: var(--background-color);
  }

  .referral-body, settings-body {
    background-image: var(--background-color);
    background-color: var(--background-color);
  }

  .sharing-and-social-media .share span, 
  .sharing-and-social-media .add-to-contacts span,
  .sharing-and-social-media .share i, 
  .sharing-and-social-media .add-to-contacts i {
    background-image: var(--background-color);
    color: var(--background-color);
  } 
  ${getGradientSetup(
    mode,
    `.sharing-and-social-media .share span, 
    .sharing-and-social-media .add-to-contacts span,
    .sharing-and-social-media .share i, 
    .sharing-and-social-media .add-to-contacts i`
  )}
`;
    },
    'font-color': (mode) => {
      return `
  .bio-collapse h2,
  .contact-button:not(.main-action-button) .contact-button-body span {
    background-image: var(--font-color);
    color: var(--font-color);
  } 

    ${getGradientSetup(
      mode,
      `.bio-collapse h2,
        .contact-button:not(.main-action-button) .contact-button-body span `
    )}
`;
    },
    'icon-color': (mode) => {
      return `
  .details-head-refer-icons i,
  .contact-button:not(.main-action-button) .contact-button-icon i,
  .bio-collapse-head-chevron i,
  .bio-collapse-head-icon i {
    background-image: var(--icon-color);
    color: var(--icon-color);
  }
  ${getGradientSetup(
    mode,
    `
  .details-head-refer-icons i,
  .contact-button:not(.main-action-button) .contact-button-icon i,
  .bio-collapse-head-chevron i,
  .bio-collapse-head-icon i`
  )}`;
    },
    'name-font-color': (mode) => {
      return `
        div.title h1.profile-title {
          background-image: var(--name-font-color);
          color: var(--name-font-color);
        }
      ${getGradientSetup(mode, 'h1.profile-title')}`;
    },
    'subtitle-font-color': (mode) => {
      return `
        div.title h2.profile-title-subtitle {
          background-image: var(--subtitle-font-color);
          color: var(--subtitle-font-color);
        }
      ${getGradientSetup(mode, 'h2.profile-title-subtitle')}`;
    },
    ...contactCssSnippets
  };
};
const CSS_TEMPLATES = {
  //   Primary Color:
  // - Details Backgound
  // - Share screen background
  // - Accordian heading font and Icons (when open)
  // - Social Media Icons Background
  //   Secondary Color:
  // - Profile picture border
  // - Action buttons background Color
  // - CTA background Color
  template_default: `
  .social-media.mobile.social-media-link,
  .social-media-link {
    color: white;
  }
  .desktop-footer-sharing {
    color: white;
  }
  @media screen and(max-width: 768px) {
      .footer-desktop {
      color: white;
    }
  }
`
};

function getTemplate(templateName) {
  if (templateName.includes('desktop')) {
    return CSS_TEMPLATES['template_default'];
  }
  return '';
}

const getColorObject = (colorObject) => {
  const defaultObject = { mode: 'normal', css: colorObject };
  if (colorObject && typeof colorObject == 'object') {
    return {
      ...defaultObject,
      ...colorObject,
      mode: includes(colorObject.mode, ['linear', 'radial'])
        ? 'gradient'
        : defaultObject.mode
    };
  } else {
    return defaultObject;
  }
};

export default function (
  masterProfileCssPlug,
  cssPlug,
  cssVariables,
  stylingTemplate,
  globalStyling,
  contactKeys
) {
  const getCssPlug = () => {
    let template = getTemplate(stylingTemplate.layout);
    const cssSnippets = getCssSnippets(contactKeys);
    for (const key in cssVariables) {
      if (cssSnippets[key])
        template += cssSnippets[key](
          cssVariables[key]?.mode,
          key.split('-').slice(-1)
        );
    }
    return template;
  };

  const getStyle = () => {
    const style = [
      stylingTemplate.cssPlug,
      `body * {
        font-family: ${stylingTemplate.fontFamily}; 
      }
      
      :root {
        ${join(
          '\n',
          values(
            mapObjIndexed(
              (value, key) => `--${key}:${getColorObject(value).css};\n`,
              cssVariables || {}
            )
          )
        )}
      }`,
      masterProfileCssPlug,
      cssPlug,
      getCssPlug(),
      globalStyling.globalCssPlug ?? ''
    ];
    return {
      cssPlugs: style,
      classes: [
        ...values(
          mapObjIndexed(
            (value, key) =>
              value ? `var-${key}-${getColorObject(value).mode}` : '',
            cssVariables || []
          )
        )
      ]
    };
  };
  return { getStyle };
}
