<template>
  <div
    class="head-short flex flex-row items-center w-full lg:h-24 md:h-48 sm:h-36 h-24"
    :class="getTW('details-head')"
    ref="headContainer"
  >
    <div
      class="overflow-hidden w-3/12 mr-2 h-full"
      :class="getTW('details-head-picture')"
      v-if="deepIf(['person', 'profile_gallery'])"
    >
      <responsive-image
        :prefix="imagePrefix"
        :image="details.person.profile_gallery[0]"
        :alt="details.person.first_name + ' ' + details.person.last_name"
        :widthMobile="'25vw'"
        :widthDesktop="'103px'"
      />
    </div>
    <div class="flex items-center justify-between flex-1">
      <div class="mr-2 flex-1" v-if="deepIf(['person'])">
        <h1
          class="leading-none"
          :style="titleSize"
          :class="getTW('details-head-title')"
          v-html="
            formatForSuperscript(
              sanitize(
                details.person.first_name + ' ' + details.person.last_name
              )
            )
          "
        >
        </h1>
        <h2
          v-if="deepIf(['biography', 'job_title'])"
          class="leading-none"
          :style="subtitleSize"
          :class="getTW('details-head-subtitle')"
          v-html="formatForSuperscript(sanitize(details.biography.job_title))"
        >
        </h2>
      </div>
      <referrals
        v-if="deepIf(['person'])"
        :message="details.referral_message"
        :email="getEmail"
        :number="getNumber"
        :profileId="details.id"
        :companyName="details.company.name"
        :profileAlias="details.profileAlias"
        :show="inShowReferrals"
        :full-name="getName"
        :iconStyling="`sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-14 lg:h-14 w-14 h-14 md:text-5xl sm:text-4xl lg:text-3xl text-3xl mr-6 ${getTW(
          'details-head-refer-icons'
        )}`"
        :showSaveButton="pwaHandler.showSaveButton"
        @saveClick="pwaHandler.handelSaveClick"
        @downloadContact="pwaHandler.vcfDownloadUrl"
      >
        <template v-slot:default>
          <div
            class="mx-6 h-0 absolute flex -mt-20 pt-1 sm:-mt-28 md:-mt-32 lg:-mt-20 right-0"
          >
            <a
              rel="nofollow"
              class="btn text-2xl leading-none ml-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center"
              :class="getTW('details-head-refer-icons')"
              v-on:click="
                captureEvent({
                  value: getNumber,
                  action: 'click',
                  type: 'whatsapp',
                  title: 'whatsapp'
                })
              "
              :href="`https://api.whatsapp.com/send?phone=${getNumber?.replace(
                '+',
                ''
              )}`"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
            <slot name="sms">
              <a
                rel="nofollow"
                class="btn text-lg sm:text-2xl lg:text-lg leading-none ml-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center"
                v-on:click="
                  captureEvent({
                    value: getNumber,
                    action: 'click',
                    type: 'sms',
                    title: `sms`
                  })
                "
                :class="getTW('details-head-refer-icons')"
                :href="`sms:${getNumber}`"
              >
                <i class="fas fa-comment-alt"></i>
              </a>
            </slot>
            <button
              class="btn text-lg sm:text-2xl lg:text-base leading-none ml-2 w-11 h-11 sm:w-12 sm:h-12 lg:w-11 lg:h-11 flex items-center justify-center"
              :class="getTW('details-head-refer-icons')"
              v-on:click="
                captureEvent({
                  value: '',
                  type: 'save-to-contacts',
                  action: 'click',
                  title: ''
                });
                vcfDownloadUrl(details);
              "
            >
              <i class="far fa-address-book ml-1"></i>
            </button>
          </div>
        </template>
      </referrals>
    </div>
  </div>
</template>
<script>
import head from './head';

export default head();
</script>
