import { filter, isEmpty, isNil, splitEvery, join, pathOr } from 'ramda';
import { isObject, replace, get, chain } from 'lodash';
import { isIos } from '~/functions/wrappers/save-and-share-wrapper';
export default (profile) => {
  const isModal = (item) => item.isModal || item.key.includes('modal:');
  const getAdditionalInfo = () =>
    filter(
      (o) => isObject(o) && !isModal(o),
      profile?.additional_information || []
    );
  const filterVideosByKey = (key) => {
    const filterForDefault = (videos) =>
      filter(({ type, embed }) => type == key && embed, videos);
    return filterForDefault(profile.video_gallery);
  };
  const filterGalleryByKey = (key) => {
    const filterForDefault = (items) => {
      const result = filter(
        ({ type }) => !(isEmpty(type) || isNil(type)) && type == key,
        items
      );
      return filter(
        ({ expireAt }) =>
          isNil(expireAt) || isEmpty(expireAt)
            ? true
            : new Date(expireAt).getTime() > new Date().getTime(),
        result
      );
    };

    return filterForDefault(profile.image_gallery);
  };

  const aboutMe = () => {
    const info = filter(
      ({ type }) => !(isEmpty(type) || isNil(type)) && type == 'aboutme',
      getAdditionalInfo()
    )?.[0] || {
      description: '',
      title: ''
    };
    return {
      ...info,
      aboutMeVideo: profile?.about_me_video || ''
    };
  };

  const additionalInformation = () =>
    filter(({ type, key, description }) => {
      const hasContent = () => {
        if (type == 'testimonials') {
          return profile.testimonials?.length;
        } else if (type == 'imageGallery') {
          return filterGalleryByKey(key)?.length;
        } else if (type == 'videoGallery') {
          return filterVideosByKey(key)?.length;
        }
        return description;
      };
      return !(isEmpty(type) || isNil(type)) && hasContent();
    }, getAdditionalInfo());

  const additionalInformationSansAboutMe = () =>
    filter(({ type }) => {
      return type != 'aboutme';
    }, additionalInformation() || []);

  const getKeyFromTestimonial = (testimonial) => {
    const author = testimonial?.author || '';
    const id = testimonial?.id || '';
    const key = [author, id];
    return join(
      '-',
      filter((k) => k != '', key)
    );
  };
  const getNormalModals = () => {
    const temp = pathOr([], ['additional_information'], profile)
      .filter((i) => isObject(i))
      .reduce((acc, cur) => {
        if (isModal(cur)) {
          acc.push({
            ...cur,
            key: replace(cur.key, 'modal:', '')
          });
        }
        return acc;
      }, []);
    return temp;
  };
  return {
    filterVideosByKey,
    filterGalleryByKey,
    testimonials: profile.testimonials || [],
    get qrCodeUrl() {
      return profile.qrcode?.url || '';
    },
    get qrCodeOptions() {
      const logo = get(
        profile,
        'logo.url',
        get(profile, 'company.logo.url', '')
      );
      const width = isIos() ? 300 : 800;
      return {
        image: chain(logo)
          .replace('/w_auto', '')
          .replace('/f_auto', '')
          .replace('upload', 'upload/w_' + width)
          .value(),
        data: document.URL,
        width: width,
        height: width,
        type: isIos() ? 'svg' : 'canvas',
        margin: 1,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'Q'
        },
        imageOptions: {
          hideBackgroundDots: true,
          margin: 5,
          crossOrigin: 'anonymous'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        dotsOptions: {
          color: get(profile, 'qrCodeData.colorOptions.dots.css', '#333333'),
          type: get(profile, 'qrCodeData.dotType', 'dots')
        },
        cornersSquareOptions: {
          color: get(
            profile,
            'qrCodeData.colorOptions.cornerSquares.css',
            '#333333'
          ),
          type: get(profile, 'qrCodeData.cornerSquareType', 'extra-rounded')
        },
        cornersDotOptions: {
          color: get(
            profile,
            'qrCodeData.colorOptions.cornerDots.css',
            '#777777'
          ),
          type: get(profile, 'qrCodeData.cornerDotType', 'dot')
        }
      };
    },
    get aboutMe() {
      return aboutMe();
    },
    get additionalInformation() {
      return additionalInformation();
    },
    get additionalInformationSansAboutMe() {
      return additionalInformationSansAboutMe();
    },
    get iconStyle() {
      return 'fa-' + (profile.iconStyle || 'duotone');
    },
    getDropdownGroup(arr, by = 4) {
      return splitEvery(by, arr);
    },
    hideControls(arr, by = 3) {
      return splitEvery(by, arr).length - 1 <= 0;
    },
    get getNormalModals() {
      return getNormalModals();
    },
    getKeyFromTestimonial
  };
};
