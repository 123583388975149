<template>
  <div class="dt-header bg-white h-20 py-5 flex justify-between">
    <div class="logo flex justify-start">
      <slot name="left"></slot>
    </div>
    <div class="sharing-and-social-media flex items-center">
      <slot name="right"></slot>
    </div>
  </div>
</template>
