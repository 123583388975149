<template>
  <swiper
    :slidesPerView="1"
    :slidesPerGroup="1"
    :spaceBetween="10"
    :breakpoints="{
      '768': {
        slidesPerView: slidesPerView,
        slidesPerGroup: slidesPerView,
        spaceBetween: spaceBetween
      }
    }"
    :navigation="true"
    :pagination="{
      clickable: true
    }"
    :modules="modules"
    class="max-w-full relative carousel"
    :class="{ 'hide-controls': hideControls }"
  >
    <slot />
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";

export default {
  props: {
    slidesPerView: {
      type: Number,
      default: 3
    },
    spaceBetween: {
      type: Number,
      default: 30
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Swiper
  },
  setup() {
    return {
      modules: [Navigation, Pagination]
    };
  }
};
</script>

<style>
.carousel.swiper {
  padding-left: 0;
  padding-right: 0;
}
.hide-controls.carousel.swiper {
  padding-left: 0;
  padding-right: 0;
}
div.swiper-wrapper {
  display: flex;
  align-items: center;
}
.hide-controls div.swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .swiper-slide {
  padding-bottom: 10px;
}
.carousel div.swiper-pagination {
  position: relative;
}
.hide-controls .carousel div.swiper-pagination {
  display: none;
}
.hide-controls.carousel.swiper {
  border-left: 0;
  border-right: 0;
}

.carousel div.swiper-button-next,
.carousel div.swiper-button-prev {
  position: absolute;
  top: 0;
  height: 100%;
  margin-top: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 25px 0;
}
.carousel div.swiper-button-next.swiper-button-disabled::after,
.carousel div.swiper-button-prev.swiper-button-disabled::after {
  opacity: 0.35;
}
.carousel div.swiper-button-next.swiper-button-disabled,
.carousel div.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}
.carousel div.swiper-button-next {
  background: white;
  right: 0px;
}
.carousel div.swiper-button-prev {
  background: white;
  left: 0px;
}
.hide-controls.carousel div.swiper-button-next,
.hide-controls.carousel div.swiper-button-prev {
  display: none;
}
.carousel div.swiper-button-next,
.carousel div.swiper-button-prev {
  display: none;
}
.carousel div.swiper-button-next,
.carousel div.swiper-button-prev {
  width: 25px;
}

.carousel div.swiper-button-next::after,
.carousel div.swiper-button-prev::after {
  font-size: 80px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  color: #292b2c;
}
.carousel div.swiper-button-prev::after {
  content: "\f104";
}
.carousel div.swiper-button-next::after {
  content: "\f105";
}
.carousel div.swiper-button-next,
.carousel div.swiper-button-prev {
  display: none;
}
@media only screen and (min-width: 768px) {
  .carousel div.swiper-button-next,
  .carousel div.swiper-button-prev {
    display: flex;
  }
  .carousel.swiper {
    padding-left: 64px;
    padding-right: 64px;
  }
  .carousel div.swiper-button-next,
  .carousel div.swiper-button-prev {
    width: 64px;
  }
}
</style>