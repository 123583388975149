<template>
  <div
    class="rounded-md overflow-hidden"
    :class="[{ 'open-collapse': isOpen, 'closed-collapse': !isOpen }]"
  >
    <div
      class="bio-collapse-head flex justify-between items-center cursor-pointer px-2 h-12 lg:h-14 sm:h-16"
      @click="changeOpenEvent"
    >
      <div class="flex items-center">
        <div
          v-if="!hideHeaderIcon && headerIcon"
          class="bio-collapse-head-icon text-lg"
        >
          <i :class="headerIcon"></i>
        </div>
        <slot name="head"></slot>
      </div>
      <div class="bio-collapse-head-chevron">
        <i v-if="isOpen" class="fas fa-chevron-up"></i>
        <i v-else class="fas fa-chevron-down"></i>
      </div>
    </div>
    <div
      class="h-0 bio-collapse-body px-4 lg:px-4"
      :class="{ 'overflow-hidden': !overflow }"
      :style="bodyHeight"
    >
      <div
        class="py-2 h-auto bio-collapse-body-inner"
        :class="{ open: isOpen }"
        ref="innerBody"
      >
        <div>
          <slot name="body"></slot>
        </div>
      </div>
    </div>
    <div @click="closeFromBottom" class="bio-collapse-footer" v-show="isOpen">
      <i class="fas fa-chevron-up bio-collapse-footer-chevron"></i>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue', 'update', 'triggerAnalytics'],
  props: {
    headerIcon: {
      default: '',
      type: String
    },
    hideHeaderIcon: {
      default: false,
      type: Boolean
    },
    keepAlive: {
      default: true,
      type: Boolean
    },
    aliveOnLoad: {
      default: false,
      type: Boolean
    },
    loadIndex: {
      defaultStatus: -1,
      type: Number
    },
    modelValue: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpen: this.modelValue,
      overflow: this.modelValue,
      keepAliveFlag: this.aliveOnLoad,
      bodyHeight: this.modelValue ? 'height: auto' : 'height: 0px'
    };
  },
  created() {
    if (!this.keepAliveFlag && this.loadIndex >= 0)
      setTimeout(() => {
        this.keepAliveFlag = true;
      }, 1000 * this.loadIndex);
  },
  watch: {
    modelValue() {
      this.changeOpen();
    }
  },
  methods: {
    changeOpenEvent() {
      this.$emit('update:modelValue', !this.modelValue);
      this.$emit('update', !this.modelValue);
    },
    changeOpen() {
      if (this.modelValue) {
        this.$emit('triggerAnalytics');
        this.$nextTick(() => {
          this.bodyHeight = `height: ${this.$refs.innerBody.clientHeight}px`;
          this.isOpen = true;
          setTimeout(() => {
            this.overflow = true;
            this.bodyHeight = 'height: auto';
          }, 200);
        });
      } else {
        this.bodyHeight = `height: ${this.$refs.innerBody.clientHeight}px`;
        setTimeout(() => {
          this.overflow = false;
          this.bodyHeight = 'height: 0px';
          setTimeout(() => {
            this.isOpen = false;
            this.keepAliveFlag = this.keepAlive;
          }, 200);
        }, 20);
      }
    },
    closeFromBottom() {
      const startPos = document.body.scrollTop;
      const startHeight = this.$refs.innerBody.parentElement.clientHeight;
      this.changeOpenEvent();
      const updater = setInterval(() => {
        document.body.scrollTop =
          startPos -
          startHeight +
          this.$refs.innerBody.parentElement.clientHeight;
      });
      setTimeout(() => {
        clearInterval(updater);
      }, 200);
    }
  }
};
</script>
<style scoped>
div.bio-collapse-body {
  transition: height ease-in-out 200ms;
}
</style>
