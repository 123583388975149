<script setup>
import aboutMeSection from '@/components/about-me-section/index.vue';
import titleCard from '@/components/title-card/index.vue';
import contactButtons from '@/components/contact-buttons/index.vue';
import saveAndShare from '@/components/save-and-share.vue';
import socialMediaButtons from '@/components/social-media-buttons.vue';
import responsiveImage from '@/components/responsive-image.vue';
import bodyContentDropdown from '@/components/body-content/body-content-dropdown.vue';
import bodyContentSection from '@/components/body-content/body-content-section.vue';
import settingsButton from '@/components/settings-button.vue';
import footerSection from '@/components/footer-section.vue';
import headerSection from '@/components/header-section.vue';
import mainSection from '@/components/main-section.vue';
import separator from '@/components/separator.vue';
import { inject } from 'vue';
defineProps({
  profileDetails: { type: Object, default: () => {} },
  saveAndShareHandler: { type: Object, default: () => {} },
  bodyContentHandler: { type: Object, default: () => {} },
  titleInfoHandler: { type: Object, default: () => {} },
  contactButtonsHandler: { type: Object, default: () => {} },
  profileImageHandler: { type: Object, default: () => {} },
  footerHandler: { type: Object, default: () => {} },
  leadFormHandler: { type: Object, default: () => {} }
});
// Open Modal
const openSettings = inject('openSettings');
const vScrollOverEvent = inject('vScrollOverEvent');
</script>
<template>
  <div
    class="desktop-template desktop-template-1 body flex flex-col items-center"
  >
    <header-section class="max-w-screen-xl w-full px-5 lg:px-0 lg:mx-0">
      <template #left>
        <responsive-image
          v-if="profileImageHandler.logo.url"
          class="h-full"
          :image="profileImageHandler.logo.url"
          :width="profileImageHandler.logo.width"
          :alt="profileImageHandler.logo.alt"
          :widthMobile="'50%'"
          :widthDesktop="'307px'"
        />
      </template>
      <template #right>
        <save-and-share
          :showSaveButton="saveAndShareHandler.showSaveButton"
          @saveClick="saveAndShareHandler.handelSaveClick()"
        />

        <social-media-buttons
          class="desktop hidden md:flex"
          background="bg-brand"
          :contacts="contactButtonsHandler.socialMedia"
        />
      </template>
    </header-section>
    <main-section
      :bannerBackground="profileImageHandler.bannerBackground"
      containerClass="max-w-screen-xl py-8 md:justify-between"
      class="w-full"
    >
      <title-card
        class="md:w-5/12 md:py-10"
        :titleInfoHandler="titleInfoHandler"
        :saveAndShareHandler="saveAndShareHandler"
      />
      <contact-buttons
        :contactButtonsHandler="contactButtonsHandler"
        class="mt-8 md:mt-0 pl-5 pr-5 md:w-1/2 md:pr-5 md:pl-20 lg:pr-0"
        data-nosnippet="false"
      />
    </main-section>
    <about-me-section
      v-scroll-over-event
      :aboutMeInfo="bodyContentHandler.aboutMe"
      :leadFormHandler="leadFormHandler"
      class="hidden md:block"
    />
    <body-content-dropdown
      :bodyContentHandler="bodyContentHandler"
      :leadFormHandler="leadFormHandler"
      class="mb-3 mt-8 px-6 pb-9 w-full md:hidden"
    />
    <body-content-section
      :bodyContentHandler="bodyContentHandler"
      class="hidden md:flex"
    />
    <save-and-share
      class="px-6 md:hidden"
      :showSaveButton="saveAndShareHandler.showSaveButton"
      :buttonLayout="true"
      @saveClick="saveAndShareHandler.handelSaveClick()"
    />
    <div class="logo flex justify-center md:hidden -mt-1 -mb-1">
      <responsive-image
        class="h-full"
        v-if="profileImageHandler.logo.url"
        :image="profileImageHandler.logo.url"
        :width="profileImageHandler.logo.width"
        :alt="profileImageHandler.logo.alt"
        :widthMobile="'50vw'"
        :widthDesktop="'307px'"
      />
    </div>
    <div class="py-9 w-full flex justify-between items-center md:hidden">
      <!-- social-media -->
      <div class="w-12"></div>
      <social-media-buttons
        class="desktop-footer-social-media mobile"
        background="bg-brand"
        :contacts="contactButtonsHandler.socialMedia"
      />

      <settings-button class="grey shadow" @click="openSettings" />
    </div>
    <div
      class="desktop-footer-sharing hidden items-center justify-between mt-12 w-full bg-brand h-16 md:flex"
    >
      <div class="w-12"></div>
      <div class="flex items-center">
        <h4 class="mr-6">Follow & Connect:</h4>
        <social-media-buttons
          class="desktop-footer-social-media desktop text-white mr-2"
          :contacts="contactButtonsHandler.socialMedia"
        />
        <separator />
        <save-and-share
          :showSaveButton="saveAndShareHandler.showSaveButton"
          @saveClick="saveAndShareHandler.handelSaveClick()"
        >
          <template #separator>
            <separator />
          </template>
        </save-and-share>
      </div>
      <settings-button class="text-white mr-4 -ml-4" @click="openSettings" />
    </div>
    <footer-section :footerHandler="footerHandler" />
  </div>
</template>
<style>
@import url('./DesktopStyling.css');

.desktop-template-1 .profile-title-subtitle {
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .desktop-template-1 .footer.footer-links {
    background-color: #e0e0e0;
  }
}

@media only screen and (min-width: 768px) {
  .desktop-template-1 .footer.footer-links a,
  .desktop-template-1 .footer.footer-links p {
    font-size: 12px;
    color: #4b4b4b;
    line-height: 16px;
  }
}
</style>
