<template>
  <div
    ref="videoContainer"
    :class="clss"
    :style="{ width, height: ratioHeight + 'px' }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ["width", "ratio", "clss"],
  data() {
    return {
      ratioHeight: 0
    };
  },
  mounted() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  },
  watch: {
    ratio() {
      this.updateHeight();
    },
    width() {
      this.updateHeight();
    }
  },
  methods: {
    updateHeight() {
      setTimeout(() => {
        if (this?.$refs?.videoContainer)
          this.ratioHeight = this.$refs.videoContainer.clientWidth * this.ratio;
      }, 150);
    }
  }
};
</script>
