<script setup>
import { computed, onMounted, ref, inject } from 'vue';
import responsiveImage from '@/components/responsive-image.vue';
import responsiveWrapper from '~/functions/wrappers/responsive-wrapper';

const vHref = inject('vHref');
const openReferrals = inject('openReferrals');
const props = defineProps({
  titleInfoHandler: {
    type: Object,
    default: () => ({})
  },
  saveAndShareHandler: {
    type: Object,
    default: () => ({})
  }
});

const iconStyling =
  'bg-white flex h-11 items-center leading-none mx-2 w-11 rounded-full lg:w-11 lg:h-11 sm:w-12 sm:h-12';
const titleContainer = ref(null);

const { getSizeScaled, setContainer } = responsiveWrapper(
  {
    scale: () => {
      return 1; //containerWidth > 384 ? (containerWidth - 384) / 1000 + 1 : 1;
    }
  },
  { size: 0, container: { clientWidth: 0 } }
);

const titleSizeCss = computed(() => {
  return props.titleInfoHandler.titleSize
    ? `font-size:${props.titleInfoHandler.titleSize * getSizeScaled()}px`
    : '';
});
const subtitleSizeCss = computed(() => {
  return props.titleInfoHandler.subtitleSize
    ? `font-size:${props.titleInfoHandler.subtitleSize * getSizeScaled()}px`
    : '';
});

onMounted(() => {
  setContainer(titleContainer.value);
});
</script>
<template>
  <div class="title flex flex-col items-center" ref="titleContainer">
    <figure
      class="title-image overflow-hidden rounded-full flex justify-center border-4 border-white md:w-52"
    >
      <responsive-image
        :image="titleInfoHandler.profileImage.url"
        :width="titleInfoHandler.profileImage.width"
        :widthMobile="'35vw'"
        :widthDesktop="'100%'"
      />
    </figure>
    <div class="title-text">
      <h1
        class="profile-title text-white pt-5 text-center leading-none"
        :style="titleSizeCss"
        v-html="titleInfoHandler.fullNameHTML"
      >
      </h1>
      <h2
        class="profile-title-subtitle text-white text pt-5 text-center"
        :style="subtitleSizeCss"
        v-html="titleInfoHandler.jobTitleHTML"
      >
      </h2>
    </div>
    <div class="title-referrals flex pt-5">
      <a
        rel="nofollow"
        v-href="
          `https://api.whatsapp.com/send?phone=${titleInfoHandler.whatsAppNumber}`
        "
        class="btn details-head-refer-icons text-2xl justify-center referral-whatsapp"
        title="WhatsApp"
        type="WhatsApp"
        analyticsTitle="WhatsApp"
        :class="iconStyling"
      >
        <i class="fab fa-whatsapp"> </i>
      </a>
      <a
        rel="nofollow"
        @click="saveAndShareHandler.vcfDownloadUrl()"
        class="btn details-head-refer-icons text-lg justify-center referral-contact sm:text-xl lg:text-lg cursor-pointer"
        :class="iconStyling"
      >
        <i class="far fa-address-book"> </i>
      </a>
      <a
        rel="nofollow"
        @click="openReferrals"
        class="btn refer details-head-refer-icons justify-center text-3xl cursor-pointer"
        :class="iconStyling"
      >
        <i class="custom-referral-icon"> </i>
      </a>
    </div>
  </div>
</template>
